import React, { useState } from "react";
import style from "./JobBoardWebsite.module.css";
import NavBar from "../../NavBar";
import img from "../../Image/Job Board Website 1.png";
import Section2 from "./Section2/Section2";
import Section3 from "./Section3/Section3";
import SixthSection from "../../SixthSection/SixthSection";
import EleventhSection from "../../EleventhSection/EleventhSection";
import Footer from "../../Footer/Footer";

import Section4 from "./Section4/Section4";
import ForthSection from "../../ForthSection/ForthSection";

import Section5 from "./Section5/Section5";
import ClientFormModal from "../../ClientFormModal";
import ProjectFormModal from "../../ProjectFormModal";
import ServicesPage from "../../ServicesPage/ServicesPage";
import IndustriesPage from "../../IndustriesPage/IndustriesPage";
import { clientModaState } from "../../Recoil";
import { useRecoilState } from "recoil";
import Section from "./Section1/Section";
import { Helmet } from "react-helmet";

function JobBoardWebsite() {
  const [showPopup, setShowPopup] = useState(false);
  const [open, setOpen] = useRecoilState(clientModaState);

  //popup
  const handleGetStartedClick = () => {
    setShowPopup(true);
  };
  return (
    <div className={style.main}>
      <Helmet>
        <title>Job Board Development</title>
        <meta charSet="utf-8" />
        <meta
          name="Job Board Development"
          content="Crafting dynamic job board websites with WordPress. Elevate your platform for seamless hiring."
        />
        <link
          rel="canonical"
          href="https://www.easyryt.com/Job-Board-Website"
        />
      </Helmet>

      <NavBar />
      <div className={style.container}>
        <div>
          <h1>WordPress Job Board Website Development Services</h1>
          <h6>
            Unleash Employment Opportunities with Professional WordPress Job
            Board Website Development Services
          </h6>
          <p>
            Welcome to EasyRyt’s specialized WordPress Job Board Website
            Development services, where ingenuity, design prowess, and technical
            expertise merge to fabricate influential platforms for connecting
            individuals searching for employment and employers. With an
            extensive comprehension of the employment market and proficiency in
            WordPress, we fashion websites that not only streamline job listings
            but also promote meaningful associations.
          </p>
          <button
            type="submit"
            className={style.submitBtn}
            onClick={() => setOpen(true)}
          >
            Get Started
          </button>
        </div>
        <div className={style.img_box}>
          <img
            className={style.img}
            src={img}
            alt="WordPress Job Board Website Development Services"
          />
        </div>
      </div>

      <ServicesPage />
      <IndustriesPage/>
      <Section2 />
      <Section3 />

   <ForthSection />
      <SixthSection />
      <EleventhSection />
      <Section5 />

      <ClientFormModal />
      <ProjectFormModal />

      <Footer />
    </div>
  );
}

export default JobBoardWebsite;
