import React, { useState } from "react";
import style from "./WhyChooseUs.module.css";
import NavBar from "../../NavBar";
import img from "../../Image/Content Marketing 2.png";
import TenthSection from "../../TenthSection/TenthSection";
import SixthSection from "../../SixthSection/SixthSection";
import EleventhSection from "../../EleventhSection/EleventhSection";
import Footer from "../../Footer/Footer";
import ThirdSection from "../../ThirdSection/ThirdSection";
import Section1 from "./Section1/Section1";
import { useRecoilState } from "recoil";
import { clientModaState } from "../../Recoil";
import { Helmet } from "react-helmet";

function WhyChooseUs() {
  const [showPopup, setShowPopup] = useState(false);
  const [open, setOpen] = useRecoilState(clientModaState);

  const handleGetStartedClick = () => {
    setShowPopup(true);
  };
  return (
    <div className={style.main}>
      <Helmet>
        <title>Why Choose Us - EasyRyt</title>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Discover why EasyRyt is the trusted choice for digital solutions. From expertise to reliability, learn how we deliver exceptional results for your business."
        />
        <link rel="canonical" href="https://www.easyryt.com/Why-Choose-Us" />
      </Helmet>
      <NavBar />
      <div className={style.container}>
        <div>
          <h1>Why Choose Us?</h1>
          <p>
            We acknowledge the importance of your project, and here are several
            compelling reasons to become a part of the Easyryt community and
            join the ranks of over 500+ clients who have relied on us for the
            past two decades.
          </p>
          <button
            type="submit"
            onClick={handleGetStartedClick}
            className={style.submitBtn}
          >
            Join Now
          </button>
        </div>
        <div>
          <img className={style.img} src={img} alt="Why Choose Us" />
        </div>
      </div>

      <ThirdSection />

      <TenthSection />
      <SixthSection />
      <EleventhSection />
      <Footer />
    </div>
  );
}

export default WhyChooseUs;
