import React, { useState, useEffect } from "react";
import style from "./TenthSection.module.css";
import logo1 from "../Image/chart.gif";
import logo2 from "../Image/frame.gif";
import logo3 from "../Image/id.gif";
import logo4 from "../Image/page.gif";
import logo5 from "../Image/setting.gif";
import logo6 from "../Image/support.gif";
import logo7 from "../Image/deployment.gif";
import logo8 from "../Image/development.gif";

const TenthSection = () => {
  const cardsData = [
    {
      no: 1,
      image: logo1,
      heading: "Improves Efficiency & Scalability",
      text: "Boosts productivity and adaptability, driving business success.",
    },
    {
      no: 2,
      image: logo2,
      heading: "Designs, Wireframes & Mockups",
      text: "Visual elements ensuring cohesive user experience and project flow.",
    },
    {
      no: 3,
      image: logo3,
      heading: "Prototype Demo",
      text: "Interactive preview of features before development completion.",
    },
    {
      no: 4,
      image: logo4,
      heading: "Change & Confirmation",
      text: "Verification and approval of modifications for accuracy.",
    },
    {
      no: 5,
      image: logo5,
      heading: "SEO",
      text: "Improves visibility, boosts organic traffic, and enhances online presence.",
    },
    {
      no: 6,
      image: logo6,
      heading: "Support & Maintenance",
      text: "Ensures smooth operation, security, and up-to-date performance.",
    },
    {
      no: 7,
      image: logo7,
      heading: "Deployment",
      text: "Releases applications for public use after testing and development.",
    },
    {
      no: 8,
      image: logo8,
      heading: "Development",
      text: "Creates and builds applications to meet specific business needs.",
    },
  ];

  const [currentCardIndex, setCurrentCardIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentCardIndex((prevIndex) => (prevIndex + 1) % cardsData.length);
    }, 3000); // 3-second delay
    return () => clearInterval(interval);
  }, [cardsData.length]);

  return (
    <div className={style.main}>
      <h1 className={style.heading}>Process We Follow</h1>
      <p className={style.subheading}>
        Unlocking growth and success through a streamlined process.
      </p>
      <div className={style.cardContainer}>
        {cardsData.map((card, index) => (
          <div
            key={index}
            className={`${style.card} ${index === currentCardIndex ? style.active : ""}`}
          >
            <span className={style.no}>{card.no}</span>
            <img className={style.img} src={card.image} alt={`process-${index + 1}`} />
            <h6 className={style.cardHeading}>{card.heading}</h6>
            <p className={style.cardText}>{card.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TenthSection;
