import React, { useState } from "react";
import style from "./ECommerceAdvertising.module.css";
import NavBar from "../../NavBar";
import img from "../../Image/E-Commerce Advertising 1.png";
import Section2 from "./Section2/Section2";
import Section3 from "./Section3/Section3";
import SixthSection from "../../SixthSection/SixthSection";
import EleventhSection from "../../EleventhSection/EleventhSection";
import Footer from "../../Footer/Footer";

import Section4 from "./Section4/Section4";
import ForthSection from "../../ForthSection/ForthSection";

import Section5 from "./Section5/Section5";
import ClientFormModal from "../../ClientFormModal";
import ProjectFormModal from "../../ProjectFormModal";
import ServicesPage from "../../ServicesPage/ServicesPage";
import IndustriesPage from "../../IndustriesPage/IndustriesPage";
import { clientModaState } from "../../Recoil";
import { useRecoilState } from "recoil";
import Section from "./Section1/Section";
import { Helmet } from "react-helmet";

function ECommerceAdvertising() {
  const [showPopup, setShowPopup] = useState(false);
  const [open, setOpen] = useRecoilState(clientModaState);

  //popup
  const handleGetStartedClick = () => {
    setShowPopup(true);
  };
  return (
    <div className={style.main}>
      <Helmet>
        <title>E-commerce Advertising Expertise</title>
        <meta charSet="utf-8" />
        <meta
          name="E-commerce Advertising Expertise"
          content="Boost sales with targeted e-commerce advertising services. Elevate your online store's success."
        />
        <link
          rel="canonical"
          href="https://www.easyryt.com/E-Commerce-Advertising"
        />
      </Helmet>

      <NavBar />
      <div className={style.container}>
        <div>
          <h1>E-commerce Advertising Services</h1>
          <h6>Unleash the Full Potential of Your Online Stores</h6>
          <p>
            In the highly competitive digital landscape of today, having an
            outstanding ecommerce website is merely the initial step. To achieve
            real success, it is essential to employ effective and focused
            advertising strategies that present your products to the appropriate
            audience at the right moment. Our team of experts specializes in
            creating unique Ecommerce Advertising solutions that elevate your
            brand, enhance conversions, and maximize profitability for your
            online business.
          </p>
          <button
            type="submit"
            className={style.submitBtn}
            onClick={() => setOpen(true)}
          >
            Get Started
          </button>
        </div>
        <div>
          <img
            className={style.img}
            src={img}
            alt="E-commerce Advertising Services"
          />
        </div>
      </div>

      <ServicesPage />
      <IndustriesPage/>
      <Section2 />
      <Section3 />

   <ForthSection />
      <SixthSection />
      <EleventhSection />
      <Section5 />

      <ClientFormModal />
      <ProjectFormModal />

      <Footer />
    </div>
  );
}

export default ECommerceAdvertising;
