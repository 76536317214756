import React, { useState } from "react";
import style from "./IOSAppAdvertising.module.css";
import NavBar from "../../NavBar";
import img from "../../Image/IOS App Advertising 1.png";
import Section2 from "./Section2/Section2";
import Section3 from "./Section3/Section3";
import SixthSection from "../../SixthSection/SixthSection";
import EleventhSection from "../../EleventhSection/EleventhSection";
import Footer from "../../Footer/Footer";

import Section4 from "./Section4/Section4";
import ForthSection from "../../ForthSection/ForthSection";

import Section5 from "./Section5/Section5";
import ClientFormModal from "../../ClientFormModal";
import ProjectFormModal from "../../ProjectFormModal";
import ServicesPage from "../../ServicesPage/ServicesPage";
import IndustriesPage from "../../IndustriesPage/IndustriesPage";
import { clientModaState } from "../../Recoil";
import { useRecoilState } from "recoil";
import Section from "./Section1/Section";
import { Helmet } from "react-helmet";

function IOSAppAdvertising() {
  const [showPopup, setShowPopup] = useState(false);
  const [open, setOpen] = useRecoilState(clientModaState);

  //popup
  const handleGetStartedClick = () => {
    setShowPopup(true);
  };
  return (
    <div className={style.main}>
      <Helmet>
        <title>Powerful iOS App Ads</title>
        <meta charSet="utf-8" />
        <meta
          name="Powerful iOS App Ads"
          content="Drive growth with specialized iOS app marketing services. Elevate downloads and engagement."
        />
        <link
          rel="canonical"
          href="https://www.easyryt.com/IOS-App-Advertising"
        />
      </Helmet>

      <NavBar />
      <div className={style.container}>
        <div>
          <h1>iOS App Marketing Services</h1>
          <h6>Crafting Revolutionary Mobile Experiences</h6>
          <p>
            Welcome to our extensive range of services for iPhone App Marketing.
            We are dedicated to helping you achieve tremendous success in the
            dynamic world of Apple app marketing. Our objective is not just to
            set goals, but to guarantee the triumph of your application within
            the vast Apple ecosystem. In order to thrive in the highly
            competitive iOS landscape, it is crucial to have a strategic ally.
            Look no further, as we are the leading authorities in promoting
            Apple applications. Our specialized skills lie in increasing your
            app's visibility, boosting user engagement, and securing high
            download rates. With a profound comprehension of iOS application
            promotion, iPhone application marketing, and the intricate nuances
            of the iPhone app market, we develop tailored approaches that will
            ensure your app stands out and ultimately prospers.
          </p>
          <button
            type="submit"
            className={style.submitBtn}
            onClick={() => setOpen(true)}
          >
            Get Started
          </button>
        </div>
        <div>
          <img
            className={style.img}
            src={img}
            alt="iOS App Marketing Services"
          />
        </div>
      </div>

      <ServicesPage />
      <IndustriesPage/>
      <Section2 />
      <Section3 />

   <ForthSection />
      <SixthSection />
      <EleventhSection />
      <Section5 />

      <ClientFormModal />
      <ProjectFormModal />

      <Footer />
    </div>
  );
}

export default IOSAppAdvertising;
