import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Button,
  Box,
  Typography,
  IconButton,
  Drawer,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import styles from "./NavBar.module.css";
import logo from "./Image/logo.png";
import ServiceList from "./ServiceList";
import { clientModaState } from "./Recoil";
import { useRecoilState } from "recoil";
import ServiceModal from "./ServiceModal"; // Import ServiceModal component

const NavBar = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [isServiceListOpen, setIsServiceListOpen] = useState(false);
  const [openClientModal, setOpenClientModal] = useRecoilState(clientModaState);
  const [openDrawer, setOpenDrawer] = useState(false); // State for Drawer visibility
  const [isServiceModalOpen, setIsServiceModalOpen] = useState(false); // State for ServiceModal visibility

  const menuItems = [
    { label: "Services" },
    { label: "About", link: "/About-Us" },
    { label: "Contact Us", link: "/Contact-Us" },
    // { label: "Blog", link: "/Blogs" },
  ];

  // Handle hover for "Services" menu and dropdown (Desktop)
  const handleMouseEnter = (index) => {
    if (menuItems[index].label === "Services") {
      setIsServiceListOpen(true);
    }
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
    setIsServiceListOpen(false);
  };

  // Handle Drawer open and close
  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  // Handle Service click in mobile view
  const handleServiceClick = () => {
    setIsServiceModalOpen(true); // Open ServiceModal
    setOpenDrawer(false); // Close drawer
  };

  // Close ServiceModal
  const handleCloseServiceModal = () => {
    setIsServiceModalOpen(false);
  };

  const drawerContent = (
    <Box className={styles.drawerMenu}>
      {menuItems.map((item, index) => (
        <Box
          key={index}
          className={styles.menuItem}
          onClick={item.label === "Services" ? handleServiceClick : toggleDrawer}
          onMouseEnter={() => handleMouseEnter(index)}
        >
          <Typography variant="body1" className={styles.menuText}>
            {item.link ? (
              <Link to={item.link} className={styles.link} onClick={toggleDrawer}>
                {item.label}
              </Link>
            ) : (
              item.label
            )}
          </Typography>
        </Box>
      ))}

      {/* Get a Quote Button in Drawer */}
      <Button
        variant="contained"
        className={styles.quoteButton}
        onClick={() => setOpenClientModal(true)}
      >
        Get a Quote
      </Button>
    </Box>
  );

  return (
    <div className={styles.navBar}>
      <Toolbar className={styles.toolBar}>
        {/* Logo Section */}
        <Typography variant="h6" className={styles.logo}>
          <Link to={"/"}>
            <img src={logo} alt="Logo" height={40} width="auto" />
          </Link>
        </Typography>

        {/* Menu Items for Desktop */}
        <Box className={styles.menu}>
          {menuItems.map((item, index) => (
            <Box
              key={index}
              className={styles.menuItem}
              onMouseEnter={() => handleMouseEnter(index)}
            >
              <Typography variant="body1" className={styles.menuText}>
                {item.link ? (
                  <Link to={item.link} className={styles.link}>
                    {item.label}
                  </Link>
                ) : (
                  item.label
                )}

                {/* Services Dropdown (Desktop only) */}
                {isServiceListOpen && hoveredIndex === index && item.label === "Services" && (
                  <Box className={styles.serviceListDropdown}>
                    <ServiceList handleMouseLeave={handleMouseLeave} />
                  </Box>
                )}
              </Typography>
            </Box>
          ))}

          {/* Get a Quote Button */}
          <Button
            variant="contained"
            className={styles.quoteButton}
            onClick={() => setOpenClientModal(true)}
          >
            Get a Quote
          </Button>
        </Box>

        {/* Hamburger Icon for Mobile */}
        <IconButton
          edge="end"
          color="inherit"
          aria-label="menu"
          onClick={toggleDrawer}
          sx={{ display: { xs: "block", sm: "none" } }} // Show only on mobile screens
        >
          <MenuIcon />
        </IconButton>
      </Toolbar>

      {/* Drawer for Mobile Menu */}
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={toggleDrawer}
        sx={{
          display: { xs: "block", sm: "none" }, // Drawer for mobile
        }}
      >
        {drawerContent}
      </Drawer>

      {/* Service Modal for Mobile */}
      {isServiceModalOpen && (
        <ServiceModal open={isServiceModalOpen} onClose={handleCloseServiceModal} />
      )}
    </div>
  );
};

export default NavBar;
