import React, { useState } from "react";
import style from "./WebsiteUIUXDesignServices.module.css";
import NavBar from "../../NavBar";
import img from "../../Image/Website UI.UX Design Services 1.png";
import Section2 from "./Section2/Section2";
import Section3 from "./Section3/Section3";
import SixthSection from "../../SixthSection/SixthSection";
import EleventhSection from "../../EleventhSection/EleventhSection";
import Footer from "../../Footer/Footer";

import Section4 from "./Section4/Section4";
import ForthSection from "../../ForthSection/ForthSection";

import Section5 from "./Section5/Section5";
import ClientFormModal from "../../ClientFormModal";
import ProjectFormModal from "../../ProjectFormModal";
import ServicesPage from "../../ServicesPage/ServicesPage";
import IndustriesPage from "../../IndustriesPage/IndustriesPage";
import { clientModaState } from "../../Recoil";
import { useRecoilState } from "recoil";
import Section from "./Section1/Section";
import { Helmet } from "react-helmet";

function WebsiteUIUXDesignServices() {
  const [showPopup, setShowPopup] = useState(false);
  const [open, setOpen] = useRecoilState(clientModaState);

  //popup
  const handleGetStartedClick = () => {
    setShowPopup(true);
  };
  return (
    <div className={style.main}>
      <Helmet>
        <title>Website UI/UX Services</title>
        <meta charSet="utf-8" />
        <meta
          name="Website UI/UX Services"
          content="Crafting captivating UI/UX for impactful websites. Elevate user experiences with expert design services."
        />
        <link
          rel="canonical"
          href="https://www.easyryt.com/Website-UIUX-Design-Services"
        />
      </Helmet>

      <NavBar />
      <div className={style.container}>
        <div>
          <h1>Website UI/UX Design Services</h1>
          <h6>Crafting Revolutionary Mobile Experiences</h6>
          <p>
            Welcome to EasyRyt, your collaborator in developing captivating
            UI/UX designs for websites that enrich user involvement and foster
            triumph in the digital realm. As a distinguished supplier of design
            services for user interfaces, we concentrate on fabricating
            aesthetically pleasing and intuitive frameworks that make a
            permanent impact on individuals exploring your online platform.
            Through our proficiency, inventiveness, and emphasis on user
            satisfaction, we metamorphose your website into an immersive digital
            odyssey.
          </p>
          <button
            type="submit"
            className={style.submitBtn}
            onClick={() => setOpen(true)}
          >
            Get Started
          </button>
        </div>
        <div className={style.img_box}>
          <img className={style.img} src={img} alt="Website UI/UX Services" />
        </div>
      </div>

      <ServicesPage />
      <IndustriesPage/>
      <Section2 />
      <Section3 />

   <ForthSection />
      <SixthSection />
      <EleventhSection />
      <Section5 />

      <ClientFormModal />
      <ProjectFormModal />

      <Footer />
    </div>
  );
}

export default WebsiteUIUXDesignServices;
