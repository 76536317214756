import React, { useState } from "react";
import style from "./BlogorPersonalWebsite.module.css";
import NavBar from "../../NavBar";
import img from "../../Image/Blog or Personal Website 1.png";
import Section2 from "./Section2/Section2";
import Section3 from "./Section3/Section3";
import SixthSection from "../../SixthSection/SixthSection";
import EleventhSection from "../../EleventhSection/EleventhSection";
import Footer from "../../Footer/Footer";

import Section4 from "./Section4/Section4";
import ForthSection from "../../ForthSection/ForthSection";

import Section5 from "./Section5/Section5";
import ClientFormModal from "../../ClientFormModal";
import ProjectFormModal from "../../ProjectFormModal";
import ServicesPage from "../../ServicesPage/ServicesPage";
import IndustriesPage from "../../IndustriesPage/IndustriesPage";
import { clientModaState } from "../../Recoil";
import { useRecoilState } from "recoil";
import Section from "./Section1/Section";
import { Helmet } from "react-helmet";

function BlogorPersonalWebsite() {
  const [showPopup, setShowPopup] = useState(false);
  const [open, setOpen] = useRecoilState(clientModaState);

  //popup
  const handleGetStartedClick = () => {
    setShowPopup(true);
  };
  return (
    <div className={style.main}>
      <Helmet>
        <title>WordPress Blog Mastery</title>
        <meta charSet="utf-8" />
        <meta
          name="WordPress Blog Mastery"
          content="Crafting personalized websites and blogs with expertise. Elevate your online presence effectively."
        />
        <link
          rel="canonical"
          href="https://www.easyryt.com/Blog-or-Personal-Website"
        />
      </Helmet>
      <NavBar />
      <div className={style.container}>
        <div>
          <h1>WordPress Blog and Personal Website Development Services</h1>
          <h6>
            Cultivate Your Digital Identity with Sophistication and
            Effectiveness
          </h6>
          <p>
            Welcome to our WordPress Blog and Personal Website Development
            Services, where we bring your virtual existence to life with the
            prowess of WordPress - the most renowned and versatile content
            management system globally. Whether you are a blogger, a visionary
            artist, or an ambitious entrepreneur, our skilled WordPress
            developers are at your disposal to transform your vision into a
            breathtaking reality. Through our comprehensive assortment of
            services, we craft WordPress websites that seamlessly combine
            exquisite aesthetics with formidable capabilities.
          </p>
          <button
            type="submit"
            className={style.submitBtn}
            onClick={() => setOpen(true)}
          >
            Get Started
          </button>
        </div>
        <div className={style.img_box}>
          <img
            className={style.img}
            src={img}
            alt="WordPress Blog and Personal Website Development Services"
          />
        </div>
      </div>

      <ServicesPage />
      <IndustriesPage/>
      <Section2 />
      <Section3 />

   <ForthSection />
      <SixthSection />
      <EleventhSection />
      <Section5 />

      <ClientFormModal />
      <ProjectFormModal />

      <Footer />
    </div>
  );
}

export default BlogorPersonalWebsite;
