import React, { useState } from "react";
import style from "./Nodejs.module.css";
import NavBar from "../../NavBar";
import img from "../../Image/Node js 1.png";
import Section2 from "./Section2/Section2";
import Section3 from "./Section3/Section3";
import SixthSection from "../../SixthSection/SixthSection";
import EleventhSection from "../../EleventhSection/EleventhSection";
import Footer from "../../Footer/Footer";

import Section4 from "./Section4/Section4";
import ForthSection from "../../ForthSection/ForthSection";

import Section5 from "./Section5/Section5";
import ClientFormModal from "../../ClientFormModal";
import ProjectFormModal from "../../ProjectFormModal";
import ServicesPage from "../../ServicesPage/ServicesPage";
import IndustriesPage from "../../IndustriesPage/IndustriesPage";
import { clientModaState } from "../../Recoil";
import { useRecoilState } from "recoil";
import Section from "./Section1/Section";
import { Helmet } from "react-helmet";

function Nodejs() {
  const [showPopup, setShowPopup] = useState(false);
  const [open, setOpen] = useRecoilState(clientModaState);

  //popup
  const handleGetStartedClick = () => {
    setShowPopup(true);
  };
  return (
    <div className={style.main}>
      <Helmet>
        <title>Node.js Development Services</title>
        <meta charSet="utf-8" />
        <meta
          name="Node.js Development Services"
          content="Building robust applications with Node.js expertise. Elevate your digital solutions seamlessly."
        />
        <link
          rel="canonical"
          href="https://www.easyryt.com/Nodejs"
        />
      </Helmet>

      <NavBar />
      <div className={style.container}>
        <div>
          <h1>Node.js Development Services</h1>
          <h6>Discover Our Extensive Array of AWS Solutions</h6>
          <p>
            Welcome to EasyRyt’s specialized page for Node.js software
            development services, where creativity intersects with
            effectiveness. Are you prepared to utilize the complete potential of
            Node.js for your application development requirements? Look no more.
            Our committed team of Node.js specialists is here to convert your
            concepts into dynamic and adaptable applications that redefine user
            encounters and propel your business advancement.
          </p>
          <button
            type="submit"
            className={style.submitBtn}
            onClick={() => setOpen(true)}
          >
            Get Started
          </button>
        </div>
        <div className={style.img_box}>
          <img
            className={style.img}
            src={img}
            alt="Node.js Development Services"
          />
        </div>
      </div>

      <ServicesPage />
      <IndustriesPage/>
      <Section2 />
      <Section3 />

   <ForthSection />
      <SixthSection />
      <EleventhSection />
      <Section5 />

      <ClientFormModal />
      <ProjectFormModal />

      <Footer />
    </div>
  );
}

export default Nodejs;
