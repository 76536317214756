import React from "react";
import style from "./TwelvethSection.module.css";
import {
  ExperienceCounter1,
  ExperienceCounter2,
  ExperienceCounter3,
  ExperienceCounter4,
  ExperienceCounter5,
} from "../Counter/Counter";

function TwelvethSection() {
  return (
    <div className={style.main}>
      <h1>Why Hire From Us?</h1>
      <p>
        We are a team of qualified development professionals adept at expanding
        your current systems' capabilities via the development and integration
        of Salesforce CRM.
      </p>
      <div className={style.container}>
        <div className={style.counterBox}>
          <h2 className={style.counter}>
            <ExperienceCounter1 />
          </h2>
          <h6>Year Experience</h6>
        </div>
        <div className={style.counterBox}>
          <h2 className={style.counter}>
            <ExperienceCounter2 />
          </h2>
          <h6>Support</h6>
        </div>
        <div className={style.counterBox}>
          <h2 className={style.counter}>
            <ExperienceCounter3 />
          </h2>
          <h6>Customization</h6>
        </div>
        <div className={style.counterBox}>
          <h2 className={style.counter}>
            <ExperienceCounter4 />
          </h2>
          <h6>Timely Delivery</h6>
        </div>
        <div className={style.counterBox}>
          <h2 className={style.counter}>
            <ExperienceCounter5 />
          </h2>
          <h6>Expertise</h6>
        </div>
      </div>
    </div>
  );
}

export default TwelvethSection;
