import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Modal,
  Box,
  TextField,
  Button,
  Typography,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useRecoilState, useSetRecoilState } from "recoil";
import { projectModalState, clientDataState, clientModaState } from "./Recoil";
import styles from "./ClientFormModal.module.css";

const ClientFormModal = () => {
  const [name, setFullName] = useState("");
  const [phone, setMobileNumber] = useState("");
  const [requestServices, setRequestServices] = useState("");
  const [email, setEmail] = useState("");
  const [requestServicesData, setRequestServicesData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [error, setError] = useState("");
  const [open, setOpen] = useRecoilState(clientModaState);
  const setModalOpen = useSetRecoilState(projectModalState);
  const setClientData = useSetRecoilState(clientDataState);

  // Validation States
  const [nameError, setNameError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [serviceError, setServiceError] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios
      .get("https://backend.easyryt.com/client/allServices")
      .then((response) => {
        setRequestServicesData(response.data.data || []);
        setLoading(false);
      })
      .catch((err) => {
        setError("Failed to load services");
        setLoading(false);
      });
  }, []);

  const validateForm = () => {
    let isValid = true;

    if (!name.trim()) {
      setNameError(true);
      isValid = false;
    } else {
      setNameError(false);
    }

    if (!phone) {
      setPhoneError(true);
      isValid = false;
    } else {
      setPhoneError(false);
    }

    if (!email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
      setEmailError(true);
      isValid = false;
    } else {
      setEmailError(false);
    }

    if (!requestServices) {
      setServiceError(true);
      isValid = false;
    } else {
      setServiceError(false);
    }

    return isValid;
  };

  const handleSubmit = () => {
    if (!validateForm()) {
      return;
    }

    setSubmitLoading(true);
    const clientData = { name, phone, requestServices, email };

    axios
      .post("https://backend.easyryt.com/client/clientInfo", clientData)
      .then(() => {
        setClientData(clientData); // Save client data to state
        setOpen(false);
        setSubmitLoading(false);
        setModalOpen(true); // Open the Project Modal
        resetForm();
      })
      .catch(() => {
        alert("Form submission failed");
        setSubmitLoading(false);
      });
  };

  const resetForm = () => {
    setFullName("");
    setMobileNumber("");
    setRequestServices("");
    setEmail("");
    setRequestServicesData([]);
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxWidth: 500,
    bgcolor: "#1e1e2f",
    boxShadow: 24,
    p: 4,
    borderRadius: "10px",
    color: "#ffffff",
  };

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Box sx={modalStyle} className={styles.modalBox}>
        <Typography
          variant="h5"
          component="h2"
          gutterBottom
          textAlign="center"
          style={{ fontWeight: 600, color: "#fff" }}
        >
          “Let’s Make Something Great Together”
        </Typography>
        <form className={styles.form}>
          {/* Full Name */}
          <TextField
            label="Full Name *"
            value={name}
            onChange={(e) => setFullName(e.target.value)}
            fullWidth
            margin="normal"
            error={nameError}
            helperText={nameError ? "Full Name is required" : ""}
            InputProps={{
              style: { color: "#fff" },
            }}
            InputLabelProps={{
              style: { color: "#fff" },
            }}
            variant="outlined"
          />

          {/* Phone Number */}
          <PhoneInput
            country={"us"}
            value={phone}
            onChange={setMobileNumber}
            inputStyle={{
              width: "100%",
              height:"50px",
              background: "#333",
              color: "#ffffff",
            }}
            buttonStyle={{ background: "#333" }}
            dropdownStyle={{ background: "#444", color: "#ffffff" }}
            inputClass={phoneError ? styles.errorInput : ""}
          />
          {phoneError && (
            <Typography color="error" variant="body2">
              Phone Number is required
            </Typography>
          )}

          {/* Email */}
          <TextField
            label="Email *"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            margin="normal"
            error={emailError}
            helperText={emailError ? "Invalid email address" : ""}
            InputProps={{
              style: { color: "#fff" },
            }}
            InputLabelProps={{
              style: { color: "#fff" },
            }}
            variant="outlined"
          />

          {/* Request Services */}
          <TextField
            label="Request Services *"
            value={requestServices}
            onChange={(e) => setRequestServices(e.target.value)}
            select
            fullWidth
            margin="normal"
            error={serviceError}
            helperText={serviceError ? "Please select a service" : ""}
            InputProps={{
              style: { color: "#fff" },
            }}
            InputLabelProps={{
              style: { color: "#fff" },
            }}
            variant="outlined"
          >
            {loading ? (
              <MenuItem disabled>Loading...</MenuItem>
            ) : (
              requestServicesData.map((service) => (
                <MenuItem key={service._id} value={service?.requestServices}>
                  {service?.requestServices}
                </MenuItem>
              ))
            )}
          </TextField>

          {/* Action Buttons */}
          <Box mt={2} display="flex" justifyContent="space-between">
            <Button
              variant="outlined"
              onClick={() => setOpen(false)}
              sx={{
                color: "#fff",
                borderColor: "#fff",
                ":hover": {
                  borderColor: "#fff",
                  backgroundColor: "#fff",
                  color: "#1e1e2f",
                },
              }}
            >
              Skip
            </Button>
            <Button
              variant="contained"
              onClick={handleSubmit}
              sx={{
                color: "#fff",
                backgroundColor: "#3f51b5",
                ":hover": {
                  backgroundColor: "#5c6bc0",
                },
              }}
            >
              {submitLoading ? <CircularProgress size={24} color="inherit" /> : "Submit"}
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default ClientFormModal;
