import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Grid, Card, Typography, Button, Divider } from "@mui/material";
import styles from "./ServiceList.module.css";

const ServiceList = ({ handleMouseLeave }) => {
  const [activeCategory, setActiveCategory] = useState("Mobile App Development");
  const navigate = useNavigate();

  // Service Categories
  const serviceCategories = [
    {
      title: "Mobile App Development",
      services: [
        { name: "Mobile App Development", path: "/Mobile-App-Development" },
        { name: "Native Mobile App Development", path: "/Native-Mobile-App-Development" },
        { name: "Cross-Platform App Development", path: "/Cross-Platform-App-Development" },
        { name: "Android Application Development Services", path: "/Android-Application-Development-Services" },
        { name: "IOS Application Development Services", path: "/IOS-Application-Development-Services" },
        { name: "Hybrid Application Development Services", path: "/Hybrid-Application-Development-Services" },
        { name: "App Backend Development", path: "/App-Backend-Development" },
        { name: "Flutter App Development", path: "/Flutter-App-Development" },
        { name: "React Native App Development", path: "/React-Native-App-Development" },
        { name: "Mobile App Testing and Testing Quality Assurance", path: "/Mobile-App-Testing-and-Testing-Quality-Assurance" },
      ],
    },
    {
      title: "Digital Marketing",
      services: [
        { name: "Search Engine Optimization (SEO)", path: "/Search-Engine-Optimization" },
        { name: "Pay-Per-Click (PPC) Advertising", path: "/Pay-Per-Click-Advertising" },
        { name: "Social Media Advertising", path: "/Social-Media-Advertising" },
        { name: "Google Advertising", path: "/Google-Advertising" },
        { name: "Facebook & Instagram Advertising", path: "/Facebook-&-Instagram-Advertising" },
        { name: "Content Marketing", path: "/Content-Marketing" },
        { name: "Social Media Marketing", path: "/Social-Media-Marketing" },
        { name: "Email Marketing", path: "/Email-Marketing" },
        { name: "Analytics and Reporting", path: "/Analytics-and-Reporting" },
        { name: "Website Traffic Advertising", path: "/Website-Traffic-Advertising" },
        { name: "E-Commerce Advertising", path: "/E-Commerce-Advertising" },
        { name: "App Install Advertising", path: "/App-Install-Advertising" },
        { name: "Android App Advertising", path: "/Android-App-Advertising" },
        { name: "IOS App Advertising", path: "/IOS-App-Advertising" },
      ],
    },
    {
      title: "Website Development",
      services: [
        { name: "E-Commerce Development", path: "/E-Commerce-Development" },
        { name: "Front-End Development", path: "/Front-End-Development" },
        { name: "Backend Development", path: "/Backend-Development" },
        { name: "Full Stack Development Services", path: "/FullStack-Development-Services" },
        { name: "Blog or Personal Website", path: "/Blog-or-Personal-Website" },
        { name: "Business Website", path: "/Business-Website" },
        { name: "Ecommerce Website / Online Store", path: "/E-commerce-Website-Online-Store" },
        { name: "Online Courses", path: "/Online-Courses" },
        { name: "Podcast Website", path: "/Podcast-Website" },
        { name: "Affiliate Website", path: "/Affiliate-Website" },
        { name: "Job Board Website", path: "/JobBoard-Website" },
        { name: "Portfolio Websites", path: "/Portfolio-Websites" },
        { name: "Travel Website", path: "/Travel-Website" },
        { name: "Fashion / Lifestyle Website", path: "/Fashion-Life-style-Website" },
      ],
    },
    {
      title: "UI/UX Design",
      services: [
        { name: "Mobile App UI / UX Design Services", path: "/Mobile-App-UIUX-Design-Services" },
        { name: "Android App UI / UX Design Services", path: "/Android-App-UIUX-Design-Services" },
        { name: "UI / UX Design Services", path: "/UIUX-Design-Services" },
        { name: "ISO UI / UX Design Services", path: "/ISO-UIUX-Design-Services" },
        { name: "Website UI / UX Design Services", path: "/Website-UIUX-Design-Services" },
      ],
    },
    {
      title: "Frontend Development",
      services: [
        { name: "React Js Development", path: "/ReactJs-Development" },
        { name: "Next js Development", path: "/Nextjs-Development" },
        { name: "Vue js Development", path: "/Vuejs-Development" },
        { name: "Angular Development", path: "/Angular-Dvelopment" },
        { name: "React Native Development", path: "/ReactNative-Development" },
      ],
    },
    {
      title: "Backend Development",
      services: [
        { name: "Node js", path: "/Nodejs" },
        { name: "Laravel", path: "/Laravel" },
        { name: "Python", path: "/Python" },
        { name: "API Development", path: "/API-Development" },
        { name: "Database Design and Management", path: "/Database-Designand-Management" },
        { name: "Backend Framework Development", path: "/Backend-Framework-Development" },
        { name: "Web Application Development", path: "/Web-Application-Development" },
        { name: "Mobile Backend Development", path: "/Mobile-Backend-Development" },
        { name: "Real-Time Data Processing", path: "/Real-TimeData-Processing" },
        { name: "Performance Optimization", path: "/Performance-Optimization" },
        { name: "Integration with Third-Party Services", path: "/Integration-with-Third-Party-Services" },
      ],
    },
    {
      title: "Cloud",
      services: [
        { name: "Azure", path: "/Azure" },
        { name: "Laravel", path: "/Aws" },
        { name: "Google Cloud", path: "/Google-Cloud" },
      ],
    },
  ];

  

  // Handle category click
  const handleCategoryClick = (title) => {
    setActiveCategory(activeCategory === title ? null : title);
  };

  // Handle service click
  const handleServiceClick = (path) => {
    navigate(path);
  };

  return (
    <Box className={styles.serviceListContainer} onMouseLeave={handleMouseLeave}>
      <Grid container spacing={2}>
        {/* Left Column: Category Titles */}
        <Grid item xs={12} md={3}>
          <Box className={styles.categoryList}>
            <Typography variant="h6" className={styles.sectionTitle}>
              Service Categories
            </Typography>
            <Divider className={styles.divider} />
            {serviceCategories.map((category, index) => (
              <Button
                key={index}
                variant="contained"
                fullWidth
                className={`${styles.categoryButton} ${
                  activeCategory === category.title ? styles.activeCategory : ""
                }`}
                onClick={() => handleCategoryClick(category.title)}
              >
                {category.title}
              </Button>
            ))}
          </Box>
        </Grid>

        {/* Right Column: Services for the Active Category */}
        <Grid item xs={12} md={9}>
          {activeCategory ? (
            <Box className={styles.serviceDetails}>
              <Typography variant="h5" className={styles.activeCategoryTitle}>
                {activeCategory}
              </Typography>
              <Divider className={styles.divider} />
              <Grid container spacing={2}>
                {serviceCategories
                  .find((category) => category.title === activeCategory)
                  ?.services.map((service, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                      <Card
                        className={styles.serviceCard}
                        onClick={() => handleServiceClick(service.path)}
                        style={{ cursor: "pointer" }}
                      >
                        <Typography variant="body2" className={styles.serviceItem}>
                          {service.name}
                        </Typography>
                      </Card>
                    </Grid>
                  ))}
              </Grid>
            </Box>
          ) : (
            <Box className={styles.placeholder}>
              <Typography variant="body1">
                Select a category to see the services.
              </Typography>
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ServiceList;
