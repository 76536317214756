import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { RecoilRoot } from 'recoil'; // Import RecoilRoot
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import PrivacyPolicy from './Components/Pages/PrivacyPolicy/PrivacyPolicy';
import NativeAppDevelopment from './Components/Pages/FirstPage/NativeAppDevelopment ';

// import NativeAppDevelopment from './Components/Pages/FirstPage/NativeAppDevelopment';
import CrossPlatformAppDevelopment from './Components/Pages/CrossPlatformAppDevelopment/CrossPlatformAppDevelopment';
import AboutUs from './Components/Pages/AboutUs/AboutUs';
import WhyChooseUs from './Components/Pages/WhyChooseUs/WhyChooseUs';
import Blog from './Components/Pages/Blog/Blog';
import ContactUs from './Components/Pages/ContactUs/ContactUs';
import AffiliateWebsite from './Components/Pages/AffiliateWebsite/AffiliateWebsite';
import AndroidApplicationDevelopmentServices from './Components/Pages/AndroidApplicationDevelopmentServices/AndroidApplicationDevelopmentServices';
import HybridApplicationDevelopmentServices from './Components/Pages/HybridApplicationDevelopmentServices/HybridApplicationDevelopmentServices';
import MobileAppDevelopment from './Components/Pages/MobileAppDevelopment/MobileAppDevelopment';
import AppBackendDevelopment from './Components/Pages/AppBackendDevelopment/AppBackendDevelopment';
import FlutterAppDevelopment from './Components/Pages/FlutterAppDevelopment/FlutterAppDevelopment';
import ReactNativeAppDevelopment from './Components/Pages/ReactNativeAppDevelopment/ReactNativeAppDevelopment';
import MobileAppTestingandTestingQualityAssurance from './Components/Pages/MobileAppTestingandTestingQualityAssurance/MobileAppTestingandTestingQualityAssurance';
import IOSApplicationDevelopmentServices from './Components/Pages/IOSApplicationDevelopmentServices/IOSApplicationDevelopmentServices';
import SearchEngineOptimization from './Components/Pages/SearchEngineOptimization/SearchEngineOptimization';
import PayPerClickAdvertising from './Components/Pages/PayPerClickAdvertising/PayPerClickAdvertising';
import SocialMediaAdvertising from './Components/Pages/SocialMediaAdvertising/SocialMediaAdvertising';
import GoogleAdvertising from './Components/Pages/GoogleAdvertising/GoogleAdvertising';
import FacebookInstagramAdvertising from './Components/Pages/Facebook&InstagramAdvertising/Facebook&InstagramAdvertising';
import ContentMarketing from './Components/Pages/ContentMarketing/ContentMarketing';
import SocialMediaMarketing from './Components/Pages/SocialMediaMarketing/SocialMediaMarketing';
import EmailMarketing from './Components/Pages/EmailMarketing/EmailMarketing';
import AnalyticsandReporting from './Components/Pages/AnalyticsandReporting/AnalyticsandReporting';
import WebsiteTrafficAdvertising from './Components/Pages/WebsiteTrafficAdvertising/WebsiteTrafficAdvertising';
import ECommerceAdvertising from './Components/Pages/ECommerceAdvertising/ECommerceAdvertising';
import AppInstallAdvertising from './Components/Pages/AppInstallAdvertising/AppInstallAdvertising';
import AndroidAppAdvertising from './Components/Pages/AndroidAppAdvertising/AndroidAppAdvertising';
import IOSAppAdvertising from './Components/Pages/IOSAppAdvertising/IOSAppAdvertising';
import ECommerceDevelopment from './Components/Pages/ECommerceDevelopment/ECommerceDevelopment';
import FrontEndDevelopment from './Components/Pages/FrontEndDevelopment/FrontEndDevelopment';
import BackendDevelopment from './Components/Pages/BackendDevelopment/BackendDevelopment';
import FullStackDevelopmentServices from './Components/Pages/FullStackDevelopmentServices/FullStackDevelopmentServices';
import BlogorPersonalWebsite from './Components/Pages/BlogorPersonalWebsite/BlogorPersonalWebsite';
import BusinessWebsite from './Components/Pages/BusinessWebsite/BusinessWebsite';
import EcommerceWebsiteOnlineStore from './Components/Pages/EcommerceWebsiteOnlineStore/EcommerceWebsiteOnlineStore';
import OnlineCourses from './Components/Pages/OnlineCourses/OnlineCourses';
import PodcastWebsite from './Components/Pages/PodcastWebsite/PodcastWebsite';
import JobBoardWebsite from './Components/Pages/JobBoardWebsite/JobBoardWebsite';
import PortfolioWebsites from './Components/Pages/PortfolioWebsites/PortfolioWebsites';
import TravelWebsite from './Components/Pages/TravelWebsite/TravelWebsite';
import FashionLifestyleWebsite from './Components/Pages/FashionLifestyleWebsite/FashionLifestyleWebsite';
import MobileAppUIUXDesignServices from './Components/Pages/MobileAppUIUXDesignServices/MobileAppUIUXDesignServices';
import AndroidAppUIUXDesignServices from './Components/Pages/AndroidAppUIUXDesignServices/AndroidAppUIUXDesignServices';
import UIUXDesignServices from './Components/Pages/UIUXDesignServices/UIUXDesignServices';
import ISOUIUXDesignServices from './Components/Pages/ISOUIUXDesignServices/ISOUIUXDesignServices';
import WebsiteUIUXDesignServices from './Components/Pages/WebsiteUIUXDesignServices/WebsiteUIUXDesignServices';
import ReactJsDevelopment from './Components/Pages/ReactJsDevelopment/ReactJsDevelopment';
import NextjsDevelopment from './Components/Pages/NextjsDevelopment/NextjsDevelopment';
import VuejsDevelopment from './Components/Pages/VuejsDevelopment/VuejsDevelopment';
import ReactNativeDevelopment from './Components/Pages/ReactNativeDevelopment/ReactNativeDevelopment';
import AngularDvelopment from './Components/Pages/AngularDvelopment/AngularDvelopment';
import Nodejs from './Components/Pages/Nodejs/Nodejs';
import Laravel from './Components/Pages/Laravel/Laravel';
import Python from './Components/Pages/Python/Python';
import APIDevelopment from './Components/Pages/APIDevelopment/APIDevelopment';
import DatabaseDesignandManagement from './Components/Pages/DatabaseDesignandManagement/DatabaseDesignandManagement';
import ServerArchitectureandConfiguration from './Components/Pages/ServerArchitectureandConfiguration/ServerArchitectureandConfiguration';
import CloudServicesIntegrationSAPIndustrySolutions from './Components/Pages/CloudServicesIntegrationSAPIndustrySolutions/CloudServicesIntegrationSAPIndustrySolutions';
import BackendFrameworkDevelopment from './Components/Pages/BackendFrameworkDevelopment/BackendFrameworkDevelopment';
import WebApplicationDevelopment from './Components/Pages/WebApplicationDevelopment/WebApplicationDevelopment';
import MobileBackendDevelopment from './Components/Pages/MobileBackendDevelopment/MobileBackendDevelopment';
import RealTimeDataProcessing from './Components/Pages/RealTimeDataProcessing/PodcasRealTimeDataProcessingtWebsite';
import PerformanceOptimization from './Components/Pages/PerformanceOptimization/PerformanceOptimization';
import IntegrationwithThirdPartyServices from './Components/Pages/IntegrationwithThirdPartyServices/IntegrationwithThirdPartyServices';
import DevOpsandContinuousIntegration from './Components/Pages/DevOpsandContinuousIntegration/DevOpsandContinuousIntegration';
import Azure from './Components/Pages/Azure/Azure';
import Aws from './Components/Pages/Aws/Aws';
import GoogleCloud from './Components/Pages/GoogleCloud/GoogleCloud';
import AppDevelopment from './Components/Pages/AppDevelopment/AppDevelopment';
import CloudSolutions from './Components/Pages/CloudSolutions/CloudSolutions';
import DigitalMarketing from './Components/Pages/DigitalMarketing/DigitalMarketing';
import WebsiteDevelopment from './Components/Pages/WebsiteDevelopment/WebsiteDevelopment';
import WordPressDevelopment from './Components/Pages/WordPressDevelopment/WordPressDevelopment';
import UIUXDesign from './Components/Pages/UIUXDesign/UIUXDesign';
import FullBlog from './Components/Pages/FullBlog/FullBlog';
import Error from './Components/Pages/Error/Error';

const AppRouter = () => (
  <RecoilRoot> {/* Wrap the entire application in RecoilRoot */}
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/Native-Mobile-App-Development" element={<NativeAppDevelopment />} />
        <Route path="/Cross-Platform-App-Development" element={<CrossPlatformAppDevelopment />} />
      <Route path="/About-Us" element={<AboutUs />} />
      <Route path="/Why-Choose-Us" element={<WhyChooseUs />} />
      <Route path="/Blogs" element={<Blog />} />
      <Route path="/Blog/:blogTitle" element={<FullBlog />} />
      <Route path="/Contact-Us" element={<ContactUs />} />
      <Route path="/Affiliate-Website" element={<AffiliateWebsite />} />
      <Route path="/Android-Application-Development-Services" element={<AndroidApplicationDevelopmentServices />} />
      <Route path="/Hybrid-Application-Development-Services" element={<HybridApplicationDevelopmentServices />} />
      <Route path="/Mobile-App-Development" element={<MobileAppDevelopment />} />
      <Route path="/App-Backend-Development" element={<AppBackendDevelopment />} />
      <Route path="/Flutter-App-Development" element={<FlutterAppDevelopment />} />
      <Route path="/React-Native-App-Development" element={<ReactNativeAppDevelopment />} />
      <Route path="/Mobile-App-Testingand-Testing-Quality-Assurance" element={<MobileAppTestingandTestingQualityAssurance />} />
      <Route path="/IOS-Application-Development-Services" element={<IOSApplicationDevelopmentServices />} />
      <Route path="/Search-Engine-Optimization" element={<SearchEngineOptimization />} />
      <Route path="/Pay-Per-Click-Advertising" element={<PayPerClickAdvertising />} />
      <Route path="/Social-Media-Advertising" element={<SocialMediaAdvertising />} />
      <Route path="/Google-Advertising" element={<GoogleAdvertising />} />
      <Route path="/Facebook-&-Instagram-Advertising" element={<FacebookInstagramAdvertising />} />
      <Route path="/Content-Marketing" element={<ContentMarketing />} />
      <Route path="/Social-Media-Marketing" element={<SocialMediaMarketing />} />
      <Route path="/Email-Marketing" element={<EmailMarketing />} />
      <Route path="/Analytics-and-Reporting" element={<AnalyticsandReporting />} />
      <Route path="/Website-Traffic-Advertising" element={<WebsiteTrafficAdvertising />} />
      <Route path="/E-Commerce-Advertising" element={<ECommerceAdvertising />} />
      <Route path="/App-Install-Advertising" element={<AppInstallAdvertising />} />
      <Route path="/Android-App-Advertising" element={<AndroidAppAdvertising />} />
      <Route path="/IOS-App-Advertising" element={<IOSAppAdvertising />} />
      <Route path="/E-Commerce-Development" element={<ECommerceDevelopment />} />
      <Route path="/FrontEnd-Development" element={<FrontEndDevelopment />} />
      <Route path="/Backend-Development" element={<BackendDevelopment />} />
      <Route path="/FullStack-Development-Services" element={<FullStackDevelopmentServices />} />
      <Route path="/Blog-or-Personal-Website" element={<BlogorPersonalWebsite />} />
      <Route path="/Business-Website" element={<BusinessWebsite />} />
      <Route path="/E-commerce-Website-Online-Store" element={<EcommerceWebsiteOnlineStore />} />
      <Route path="/Online-Courses" element={<OnlineCourses />} />
      <Route path="/Podcast-Website" element={<PodcastWebsite />} />
      <Route path="/JobBoard-Website" element={<JobBoardWebsite />} />
      <Route path="/Portfolio-Websites" element={<PortfolioWebsites />} />
      <Route path="/Travel-Website" element={<TravelWebsite />} />
      <Route path="/Fashion-Life-style-Website" element={<FashionLifestyleWebsite />} />
      <Route path="/Mobile-App-UIUX-Design-Services" element={<MobileAppUIUXDesignServices />} />
      <Route path="/Android-App-UIUX-Design-Services" element={<AndroidAppUIUXDesignServices />} />
      <Route path="/UIUX-Design-Services" element={<UIUXDesignServices />} />
      <Route path="/ISO-UIUX-Design-Services" element={<ISOUIUXDesignServices />} />
      <Route path="/Website-UIUX-Design-Services" element={<WebsiteUIUXDesignServices />} />
      <Route path="/ReactJs-Development" element={<ReactJsDevelopment />} />
      <Route path="/Nextjs-Development" element={<NextjsDevelopment />} />
      <Route path="/Vuejs-Development" element={<VuejsDevelopment />} />
      <Route path="/ReactNative-Development" element={<ReactNativeDevelopment />} />
      <Route path="/Angular-Dvelopment" element={<AngularDvelopment />} />
      <Route path="/Nodejs" element={<Nodejs />} />
      <Route path="/Laravel" element={<Laravel />} />
      <Route path="/Python" element={<Python />} />
      <Route path="/API-Development" element={<APIDevelopment />} />
      <Route path="/Database-Designand-Management" element={<DatabaseDesignandManagement />} />
      <Route path="/Server-Architecture-and-Configuration" element={<ServerArchitectureandConfiguration />} />
      <Route path="/Cloud-Services-Integration-SAP-Industry-Solutions" element={<CloudServicesIntegrationSAPIndustrySolutions />} />
      <Route path="/Backend-Framework-Development" element={<BackendFrameworkDevelopment />} />
      <Route path="/Web-Application-Development" element={<WebApplicationDevelopment />} />
      <Route path="/Mobile-Backend-Development" element={<MobileBackendDevelopment />} />
      <Route path="/Real-TimeData-Processing" element={<RealTimeDataProcessing />} />
      <Route path="/Performance-Optimization" element={<PerformanceOptimization />} />
      <Route path="/Integration-with-Third-Party-Services" element={<IntegrationwithThirdPartyServices />} />
      <Route path="/DevOps-and-Continuous-Integration" element={<DevOpsandContinuousIntegration />} />
      <Route path="/Azure" element={<Azure />} />
      <Route path="/Aws" element={<Aws />} />
      <Route path="/Google-Cloud" element={<GoogleCloud />} />
      <Route path="/App-Development" element={<AppDevelopment />} />
      <Route path="/Cloud-Solutions" element={<CloudSolutions />} />
      <Route path="/Digital-Marketing" element={<DigitalMarketing />} />
      <Route path="/Website-Development" element={<WebsiteDevelopment />} />
      <Route path="/Word-Press-Development" element={<WordPressDevelopment />} />
      <Route path="/UIUX-Design" element={<UIUXDesign />} />
      <Route element={<Error />} />
      </Routes>
    </BrowserRouter>
  </RecoilRoot>
);

ReactDOM.render(<AppRouter />, document.getElementById('root'));

// For performance measurement
reportWebVitals();
