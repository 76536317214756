import React from "react";
import style from "./Section4.module.css";
import { Link } from "react-router-dom";

function Section4() {
  return (
    <div className={style.main}>
      <p>
        This is the perfect moment to enhance your online existence and elevate
        your brand to unprecedented levels with EasyRyt! Our WordPress Business
        Website Development offerings are meticulously crafted to empower your
        brand with an impactful website that captivates audiences, fosters
        interaction, and fosters achievement.
      </p>
      <h2>Get in touch with us today</h2>
      <p>
        Let’s discuss discuss how we can enhance your brand with a Native mobile
        app that sets you apart in the digital landscape!
      </p>
      <button
        onClick={() => (window.location.href = "/Contact-Us")}
        className={style.btn}
      >
        Start Conversation
      </button>
    </div>
  );
}

export default Section4;
