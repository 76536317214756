import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Grid,
  TextField,
  Button,
  Typography,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  Snackbar,
} from "@mui/material";
import { useRecoilState } from "recoil";
import { clientDataState, projectModalState } from "./Recoil";
import axios from "axios";
import MuiAlert from "@mui/material/Alert";

const ProjectFormModal = ({ open }) => {
  const [isModalOpen, setModalOpen] = useRecoilState(projectModalState);
  const [description, setDescription] = useState("");
  const [amount, setAmount] = useState("");
  const [projectFile, setProjectFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [requestServicesData, setRequestServicesData] = useState([]);
  const [selectedService, setSelectedService] = useState("");
  const [successMessage, setSuccessMessage] = useState(false);
  const [clientData ,setClientData] = useRecoilState(clientDataState)
  const [descriptionError, setDescriptionError] = useState(false);
  const [amountError, setAmountError] = useState(false);

  const handleClose =()=>{
    setModalOpen(false)
  }
  // Fetching request services
  useEffect(() => {
    setLoading(true);
    axios
      .get("https://backend.easyryt.com/client/allServices")
      .then((response) => {
        setRequestServicesData(response.data.data || []);
        setLoading(false);
      })
      .catch((err) => {
        setError("Failed to load services");
        setLoading(false);
      });
  }, []);

  const handleSubmit = async () => {
    let hasError = false;

    // Validate Description
    if (!description.trim()) {
      setDescriptionError(true);
      hasError = true;
    } else {
      setDescriptionError(false);
    }

    // Validate Amount
    if (!amount.trim()) {
      setAmountError(true);
      hasError = true;
    } else {
      setAmountError(false);
    }

    if (hasError) return;

    const formData = new FormData();
    formData.append("name", clientData?.name || "");
    formData.append("email", clientData?.email || "");
    formData.append("phone", clientData?.phone || "");
    formData.append("requestServices", selectedService || clientData?.requestServices || "");
    formData.append("description", description);
    formData.append("amount", amount);
    if (projectFile) {
      formData.append("projectFile", projectFile);
    }

    try {
      setLoading(true);
      setError(null);

      const response = await axios.post(
        "https://backend.easyryt.com/client/clientProject",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log("API Response:", response.data);

      // Clear form data after successful submission
      setDescription("");
      setAmount("");
      setProjectFile(null);
      setSelectedService("");
      setSuccessMessage(true); // Show success message

      handleClose();
    } catch (err) {
      console.error("Error submitting project data:", err);
      setError("Failed to submit the project data. Please try again.");
    } finally {
      setLoading(false);
    }
  };


  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type !== "application/pdf") {
      setError("Only PDF files are allowed.");
      setProjectFile(null);
    } else {
      setProjectFile(file);
      setError(null); // Reset error if file is valid
    }
  };

  const handleAmountChange = (e) => {
    // Ensure the amount is numeric and prefixed with '$'
    const value = e.target.value;
    const formattedAmount = value.replace(/[^0-9.]/g, ""); // Remove non-numeric characters except decimal
    setAmount(formattedAmount ? `$${formattedAmount}` : "");
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxWidth: 600,
    bgcolor: "#1e1e2f",
    p: 4,
    borderRadius: "10px",
    color: "#ffffff",
    boxShadow: 24,
  };

  const inputStyle = {
    "& .MuiInputBase-input": {
      color: "#ffffff", // White text for input
    },
    "& .MuiInputLabel-root": {
      color: "#ffffff", // White text for label
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#ffffff",
      },
      "&:hover fieldset": {
        borderColor: "#ffffff",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#4caf50", // Green color on focus
      },
    },
  };

  return (
    <>
      <Modal open={open || isModalOpen} onClose={handleClose}>
        <Box sx={modalStyle}>
          <Typography variant="h5" gutterBottom sx={{ textAlign: "center" }}>
            Project Form
          </Typography>
          {error && (
            <Typography color="error" variant="body2" gutterBottom>
              {error}
            </Typography>
          )}
          <form>
            <Grid container spacing={2}>
              {/* First Row - Name, Email, Phone, and Request Service */}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Name"
                  value={clientData?.name || ""}
                  fullWidth
                  margin="normal"
                  InputProps={{
                    style: { color: "#fff" }, // Text field text color
                  }}
                  InputLabelProps={{
                    style: { color: "#fff" }, // Label text color
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Email"
                  value={clientData?.email || ""}
                  fullWidth
                  margin="normal"
                  InputProps={{
                    style: { color: "#fff" }, // Text field text color
                  }}
                  InputLabelProps={{
                    style: { color: "#fff" }, // Label text color
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Phone"
                  value={clientData?.phone || ""}
                  fullWidth
                  margin="normal"
                  InputProps={{
                    style: { color: "#fff" }, // Text field text color
                  }}
                  InputLabelProps={{
                    style: { color: "#fff" }, // Label text color
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth margin="normal">
                  <InputLabel sx={{ color: "#fff" }}>Request Service</InputLabel>
                  <Select
                    value={selectedService || clientData?.requestServices || ""}
                    onChange={(e) => setSelectedService(e.target.value)}
                    sx={{
                      "& .MuiSelect-select": { color: "#fff" },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#fff",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#fff",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#4caf50",
                      },
                    }}
                    label="Request Service"
                  >
                    {requestServicesData.map((service) => (
                      <MenuItem key={service._id} value={service?.requestServices}>
                        {service?.requestServices}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* Remaining Fields */}
              <Grid item xs={12}>
                <TextField
                  label="Description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  fullWidth
                  margin="normal"
                  sx={inputStyle}
                  error={descriptionError}
                  helperText={descriptionError ? "Description is required" : ""}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Amount (in $)"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  fullWidth
                  margin="normal"
                  sx={inputStyle}
                  error={amountError}
                  helperText={amountError ? "Amount is required" : ""}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel sx={{ color: "#ffffff", marginBottom: "8px" }}>
                  Project File (PDF)
                </InputLabel>
                <input
                  type="file"
                  accept="application/pdf"
                  onChange={handleFileChange}
                  style={{
                    color: "#ffffff",
                    border: "1px solid #ffffff",
                    borderRadius: "5px",
                    padding: "8px",
                    width: "100%",
                    backgroundColor: "#2e2e3f",
                  }}
                />
              </Grid>
            </Grid>
            <Box mt={3} display="flex" justifyContent="space-between">
              <Button
                variant="outlined"
                onClick={handleClose}
                disabled={loading}
                sx={{ color: "#ffffff", borderColor: "#ffffff" }}
              >
                Skip
              </Button>
              <Button
                variant="contained"
                onClick={handleSubmit}
                disabled={loading}
                sx={{ backgroundColor: "#4caf50", color: "#ffffff" }}
              >
                {loading ? "Submitting..." : "Submit"}
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>

      <Snackbar
        open={successMessage}
        autoHideDuration={6000}
        onClose={() => setSuccessMessage(false)}
      >
        <MuiAlert onClose={() => setSuccessMessage(false)} severity="success">
          Project submitted successfully!
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default ProjectFormModal;
