import React from "react";
import style from "./FormSection.module.css";
import {
  Typography,
  Grid,
  Box,
  Card,
  CardContent,
} from "@mui/material";
import AttractForm from "./AttractForm";

function FormSection() {
  return (
    <div className={style.formSectionWrapper}>
      <Grid container className={style.gridContainer} spacing={4}>
            {/* Form Section */}
            <Grid item xs={12} md={6}>
          <AttractForm />
        </Grid>
        {/* Quotes Section */}
        <Grid item xs={12} md={6}>
          <Box className={style.quotesContainer}>
            <Typography variant="h3" className={style.title}>
              Unlock Your Dream Project Today!
            </Typography>
            <Card className={style.quoteCard}>
              <CardContent>
                <Typography variant="h5" className={style.quoteText}>
                  "The best way to predict the future is to create it. Let’s get started on your journey."
                </Typography>
                <Typography variant="body2" className={style.authorText}>
                  - Easyryt
                </Typography>
              </CardContent>
            </Card>
          </Box>
        </Grid>

    
      </Grid>
    </div>
  );
}

export default FormSection;
