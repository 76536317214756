import * as React from "react";
import style from "./ClientsAccordion.module.css";
import { ClientServicesaccordian } from "../Accordion/Accordion";

export default function ClientsAccordion() {
  return (
    <div className={style.main}>
      <h1 className={style.heading}>Frequently Asked Questions</h1>
      <p className={style.description}>
        We listen to queries and provide solutions that captivate users. Feel free
        to contact us if you have any query that is not mentioned below.
      </p>
      <div className={style.container}>
        <ClientServicesaccordian />
      </div>
    </div>
  );
}
