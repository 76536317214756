import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import styles from "./EleventhSection.module.css";

// Importing images
import Zuluresh from "../Image/Zuluresh Logo.webp";
import pdfCompressor from "../Image/Pdf compressor Logo.webp";
import pdfMerger from "../Image/Pdf meregr Logo.webp";
import lmageToPdf from "../Image/Inmage to pdf Logo.webp";
import QrAndBarCodeScanner from "../Image/Qr and Bar code scanner Logo.webp";
import TheNewsTale from "../Image/The News Tale Logo.png";
import EasyTripss from "../Image/EasyTripss Logo.png";
import LBB from "../Image/LBB Logo.png";
import NotaAi from "../Image/Noat Ai Logo.webp";

// Responsive settings for different screen sizes
const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 1, // Slide one item at a time
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 2,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 768, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

// Slider data with URLs, headings, and images
const sliderData = [
  {
    url: "https://play.google.com/store/apps/details?id=com.zuluresh",
    heading: "Zuluresh",
    image: Zuluresh,
  },
  {
    url: "https://www.luxurybubblebasket.com/",
    heading: "Luxury Bubble Basket",
    image: LBB,
  },
  {
    url: "https://play.google.com/store/apps/details?id=com.notaAINotesAIChatOCR",
    heading: "Nota Ai",
    image: NotaAi,
  },
  {
    url: "https://play.google.com/store/apps/details?id=com.qrAndBarCodeScanner",
    heading: "Qr And Bar Code Scanner",
    image: QrAndBarCodeScanner,
  },
  {
    url: "https://play.google.com/store/apps/details?id=com.imagesToPdfsConverter",
    heading: "Image to Pdf",
    image: lmageToPdf,
  },
  {
    url: "https://play.google.com/store/apps/details?id=com.pdf_compressor&pcampaignid=web_share",
    heading: "Pdf Compressor",
    image: pdfCompressor,
  },
  {
    url: "https://play.google.com/store/apps/details?id=com.pdf_Merger_Tool",
    heading: "Pdf Merger",
    image: pdfMerger,
  },
  {
    url: "https://thenewstale.com/",
    heading: "The News Tale",
    image: TheNewsTale,
  },
  {
    url: "https://easytripss.com/",
    heading: "EasyTripss",
    image: EasyTripss,
  },
];

// Slider component
const Slider = () => {
  return (
    <div className={styles.sliderContainer}>
      <h1 className={styles.heading}>Our Projects</h1>
      <Carousel
        responsive={responsive}
        autoPlay={true}
        autoPlaySpeed={3000}
        swipeable={true}
        draggable={true}
        showDots={true}
        infinite={true}
        keyBoardControl={true}
        customTransition="all 0.5s ease"
        transitionDuration={500}
        containerClass="carousel-container"
        dotListClass={styles.customDotList}
        itemClass="carousel-item-padding-40-px"
      >
        {sliderData.map((item, index) => (
          <div
            key={index}
            className={styles.sliderItem}
            onClick={() => window.open(item.url, "_blank")}
          >
            <img src={item.image} alt={item.heading} className={styles.sliderImage} />
            <h3 className={styles.sliderHeading}>{item.heading}</h3>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default Slider;
