import React from "react";
import style from "./ContactUs.module.css";
import NavBar from "../../NavBar";
import logo1 from "../../Image/Dominos.png";
import logo2 from "../../Image/kotlin.png";
import logo3 from "../../Image/800px-Kentucky_Fried_Chicken_201x_logo.svg.png";
import logo4 from "../../Image/Mars.png";
import logo5 from "../../Image/pngwing.com (2).png";
import logo6 from "../../Image/SAP_(6).png";
import Footer from "../../Footer/Footer";
import EleventhSection from "../../EleventhSection/EleventhSection";
import AttractForm from "../../AttractForm";
import ProjectFormModal from "../../ProjectFormModal";
import { Helmet } from "react-helmet";
import ClientFormModal from "../../ClientFormModal";

const ContactUs = () => {
  return (
    <div className={style.main}>
      <Helmet>
        <title>Contact Us - EasyRyt</title>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Get in touch with EasyRyt for expert digital solutions. Contact us today for inquiries, support, or collaboration opportunities."
        />
        <link rel="canonical" href="https://www.easyryt.com/Contact-Us" />
      </Helmet>
      <NavBar />
      <div className={style.headerSection}>
        <h1 className={style.heading}>
          Discover the Future of Technology Together!
        </h1>
        <p className={style.para}>
          Every day, Easyryt professionals empower businesses worldwide to
          fast-track their digital evolution and create a more resilient,
          sustainable, and equitable future together.
        </p>
      </div>
      <div className={style.container}>
        <div className={style.formBox}>
          <AttractForm />
        </div>
        <div className={style.rightBox}>
          <h3>Trusted by Leading Brands</h3>
          <div className={style.logoGrid}>
            <div className={style.logoItem}>
              <img className={style.img} src={logo1} alt="Dominos" />
            </div>
            <div className={style.logoItem}>
              <img className={style.img} src={logo2} alt="Kotlin" />
            </div>
            <div className={style.logoItem}>
              <img className={style.img} src={logo3} alt="KFC" />
            </div>
            <div className={style.logoItem}>
              <img className={style.img} src={logo4} alt="Mars" />
            </div>
            <div className={style.logoItem}>
              <img className={style.img} src={logo5} alt="Brand" />
            </div>
            <div className={style.logoItem}>
              <img className={style.img} src={logo6} alt="SAP" />
            </div>
          </div>
        </div>
      </div>
      <EleventhSection />
      <Footer />
      <ProjectFormModal />
      <ClientFormModal />
    </div>
  );
};

export default ContactUs;
