import React, { useState } from "react";
import style from "./ServerArchitectureandConfiguration.module.css";
import NavBar from "../../NavBar";
import img from "../../Image/Server Architecture and Configuration, 1.png";
import Section2 from "./Section2/Section2";
import Section3 from "./Section3/Section3";
import SixthSection from "../../SixthSection/SixthSection";
import EleventhSection from "../../EleventhSection/EleventhSection";
import Footer from "../../Footer/Footer";

import Section4 from "./Section4/Section4";
import ForthSection from "../../ForthSection/ForthSection";

import Section5 from "./Section5/Section5";
import ClientFormModal from "../../ClientFormModal";
import ProjectFormModal from "../../ProjectFormModal";
import ServicesPage from "../../ServicesPage/ServicesPage";
import IndustriesPage from "../../IndustriesPage/IndustriesPage";
import { clientModaState } from "../../Recoil";
import { useRecoilState } from "recoil";
import Section from "./Section1/Section";
import { Helmet } from "react-helmet";

function ServerArchitectureandConfiguration() {
  const [showPopup, setShowPopup] = useState(false);
  const [open, setOpen] = useRecoilState(clientModaState);

  //popup
  const handleGetStartedClick = () => {
    setShowPopup(true);
  };
  return (
    <div className={style.main}>
      <Helmet>
        <title>Server Architecture Experts</title>
        <meta charSet="utf-8" />
        <meta
          name="Server Architecture Experts"
          content="Optimize performance with our server configuration services. Elevate your digital infrastructure seamlessly."
        />
        <link
          rel="canonical"
          href="https://www.easyryt.com/Server-Architecture-and-Configuration"
        />
      </Helmet>

      <NavBar />
      <div className={style.container}>
        <div>
          <h1>Expert Server Architecture and Configuration Services</h1>
          <h6>Discover Our Extensive Array of AWS Solutions</h6>
          <p>
            Welcome to EasyRyt’s specialized Server Structure and Setup
            Solutions page, where expertise converges with streamlined server
            performance. Are you ready to unleash the complete capabilities of
            your server framework? You need search no more. Our seasoned team of
            server planners is here to metamorphose your server ecosystem into a
            dominant force of effectiveness, expandability, and dependability.
          </p>
          <button
            type="submit"
            className={style.submitBtn}
            onClick={() => setOpen(true)}
          >
            Get Started
          </button>
        </div>
        <div className={style.img_box}>
          <img
            className={style.img}
            src={img}
            alt="Expert Server Architecture and Configuration Services"
          />
        </div>
      </div>

      <ServicesPage />
      <IndustriesPage/>
      <Section2 />
      <Section3 />

   <ForthSection />
      <SixthSection />
      <EleventhSection />
      <Section5 />

      <ClientFormModal />
      <ProjectFormModal />

      <Footer />
    </div>
  );
}

export default ServerArchitectureandConfiguration;
