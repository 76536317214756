import React, { useState } from "react";
import style from "./GoogleCloud.module.css";
import NavBar from "../../NavBar";
import img from "../../Image/Google Cloud 1.png";
import Section2 from "./Section2/Section2";
import Section3 from "./Section3/Section3";
import SixthSection from "../../SixthSection/SixthSection";
import EleventhSection from "../../EleventhSection/EleventhSection";
import Footer from "../../Footer/Footer";

import Section4 from "./Section4/Section4";
import ForthSection from "../../ForthSection/ForthSection";

import Section5 from "./Section5/Section5";
import ClientFormModal from "../../ClientFormModal";
import ProjectFormModal from "../../ProjectFormModal";
import ServicesPage from "../../ServicesPage/ServicesPage";
import IndustriesPage from "../../IndustriesPage/IndustriesPage";
import { clientModaState } from "../../Recoil";
import { useRecoilState } from "recoil";
import Section from "./Section1/Section";
import { Helmet } from "react-helmet";

function GoogleCloud() {
  const [showPopup, setShowPopup] = useState(false);
  const [open, setOpen] = useRecoilState(clientModaState);

  //popup
  const handleGetStartedClick = () => {
    setShowPopup(true);
  };
  return (
    <div className={style.main}>
      <Helmet>
        <title>Google Cloud Services</title>
        <meta charSet="utf-8" />
        <meta
          name="Google Cloud Services"
          content="Leverage expert Google Cloud services for seamless digital solutions. Elevate your infrastructure effortlessly."
        />
        <link
          rel="canonical"
          href="https://www.easyryt.com/Google-Cloud"
        />
      </Helmet>

      <NavBar />
      <div className={style.container}>
        <div>
          <h1>Google Cloud Services</h1>
          <h6>
            Discover the Limitless Possibilities of Our Google Cloud Offerings
          </h6>
          <p>
            Embrace the opportunities that await on EasyRyt’s exclusive page
            devoted to Google Cloud solutions, where groundbreaking advancements
            and streamlined efficiency merge seamlessly. Are you prepared to
            reshape your enterprise using the state-of-the-art features found
            within Google Cloud Platform (GCP)? Your search ends here. Our
            extensive assortment of Google Cloud offerings has been intricately
            crafted to enhance your operational potential and furnish you with a
            commanding advantage in the ever-evolving digital realm.
          </p>
          <button
            type="submit"
            className={style.submitBtn}
            onClick={() => setOpen(true)}
          >
            Get Started
          </button>
        </div>
        <div className={style.img_box}>
          <img className={style.img} src={img} alt="GoogleCloud" />
        </div>
      </div>

      <ServicesPage />
      <IndustriesPage/>
      <Section2 />
      <Section3 />

   <ForthSection />
      <SixthSection />
      <EleventhSection />
      <Section5 />

      <ClientFormModal />
      <ProjectFormModal />

      <Footer />
    </div>
  );
}

export default GoogleCloud;
