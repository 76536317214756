import React, { useState } from "react";
import style from "./SocialMediaAdvertising.module.css";
import NavBar from "../../NavBar";
import img from "../../Image/social media marketing 1.png";
import Section2 from "./Section2/Section2";
import Section3 from "./Section3/Section3";
import SixthSection from "../../SixthSection/SixthSection";
import EleventhSection from "../../EleventhSection/EleventhSection";
import Footer from "../../Footer/Footer";

import Section4 from "./Section4/Section4";
import ForthSection from "../../ForthSection/ForthSection";

import Section5 from "./Section5/Section5";
import ClientFormModal from "../../ClientFormModal";
import ProjectFormModal from "../../ProjectFormModal";
import ServicesPage from "../../ServicesPage/ServicesPage";
import IndustriesPage from "../../IndustriesPage/IndustriesPage";
import { clientModaState } from "../../Recoil";
import { useRecoilState } from "recoil";
import Section from "./Section1/Section";
import { Helmet } from "react-helmet";

function SocialMediaAdvertising() {
  const [showPopup, setShowPopup] = useState(false);
  const [open, setOpen] = useRecoilState(clientModaState);

  //popup
  const handleGetStartedClick = () => {
    setShowPopup(true);
  };
  return (
    <div className={style.main}>
      <Helmet>
        <title>Strategic Social Media Ad Services</title>
        <meta charSet="utf-8" />
        <meta
          name="Strategic Social Media Ad Services"
          content="Amplify reach through impactful social media advertising. Elevate engagement and brand awareness."
        />
        <link
          rel="canonical"
          href="https://www.easyryt.com/Social-Media-Advertising"
        />
      </Helmet>

      <NavBar />
      <div className={style.container}>
        <div>
          <h1>Social Media Advertising</h1>
          <h6>Crafting Revolutionary Mobile Experiences</h6>
          <p>
            In the fast-paced digital world, cell programs have become a
            critical medium for businesses to interact with their customers. A
            crucial element of a hit cell app is its backend – the engine that
            powers the app's capability, overall performance, and user
            experience. The focus should be on crafting strong and efficient app
            backends that lay the basis for high-quality app reports. Our App
            Backend Development offerings are designed to empower your app with
            seamless functionality, complete control, safety, and scalability.
          </p>
          <button
            type="submit"
            className={style.submitBtn}
            onClick={() => setOpen(true)}
          >
            Get Started
          </button>
        </div>
        <div className={style.img_box}>
          <img className={style.img} src={img} alt="Social Media Advertising" />
        </div>
      </div>

      <ServicesPage />
      <IndustriesPage/>
      <Section2 />
      <Section3 />

   <ForthSection />
      <SixthSection />
      <EleventhSection />
      <Section5 />

      <ClientFormModal />
      <ProjectFormModal />

      <Footer />
    </div>
  );
}

export default SocialMediaAdvertising;
