import React, { useState } from "react";
import style from "./WordPressDevelopment.module.css";
import NavBar from "../../NavBar";
import img from "../../Image/Web Application Development, 2.png";
import Section1 from "./Section1/Section1";
import Section2 from "../FirstPage/Section2/Section2";
import Section3 from "../FirstPage/Section3/Section3";
import TenthSection from "../../TenthSection/TenthSection";
import SixthSection from "../../SixthSection/SixthSection";
import EleventhSection from "../../EleventhSection/EleventhSection";
import Footer from "../../Footer/Footer";
import { useRecoilState } from "recoil";
import { clientModaState } from "../../Recoil";
import { Helmet } from "react-helmet";
import ServicesPage from "../../ServicesPage/ServicesPage";
import IndustriesPage from "../../IndustriesPage/IndustriesPage";

function WordPressDevelopment() {
  const [showPopup, setShowPopup] = useState(false);
  const [open, setOpen] = useRecoilState(clientModaState);

  return (
    <div className={style.main}>
      <Helmet>
        <title>WordPress Development Services - EasyRyt</title>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Elevate your online presence with EasyRyt's WordPress development services. Build secure, scalable, and customizable websites tailored to your business needs."
        />
        <link
          rel="canonical"
          href="https://www.easyryt.com/Word-Press-Development"
        />
      </Helmet>
      <NavBar />
      <div className={style.container}>
        <div>
          <h1>WordPress Development Services</h1>
          <p>
            Expert WordPress Development Services: Custom themes, plugins, and
            responsive websites tailored to your needs. Enhance functionality
            and design for a seamless online presence.
          </p>
          <button
            type="submit"
            className={style.submitBtn}
            onClick={() => setOpen(true)}
          >
            Get Started
          </button>
        </div>
        <div>
          <img
            className={style.img}
            src={img}
            alt="WordPress Development Services"
          />
        </div>
      </div>

      <ServicesPage />
      <IndustriesPage />
      <Section2 />
      <Section3 />
      <TenthSection />
      <SixthSection />
      <EleventhSection />

      <Footer />
      {/* Popup window */}
    </div>
  );
}

export default WordPressDevelopment;
