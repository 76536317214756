import React from "react";
import styles from "./Slider.module.css";

const Slider = () => {
  const images = [
    "https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/1.png",
    "https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/2.png",
    "https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/3.png",
    "https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/4.png",
    "https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/5.png",
    "https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/6.png",
    "https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/7.png",
  ];

  return (
    <div className={styles.slider}>
      <div className={styles.slideTrack}>
        {images.map((image, index) => (
          <div className={styles.slide} key={index}>
            <img src={image} alt={`Slide ${index + 1}`} />
          </div>
        ))}
        {images.map((image, index) => (
          <div className={styles.slide} key={index + images.length}>
            <img src={image} alt={`Slide ${index + 1}`} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Slider;
