import React, { useState } from "react";
import style from "./VuejsDevelopment.module.css";
import NavBar from "../../NavBar";
import img from "../../Image/Vue js Development 1.png";
import Section2 from "./Section2/Section2";
import Section3 from "./Section3/Section3";
import SixthSection from "../../SixthSection/SixthSection";
import EleventhSection from "../../EleventhSection/EleventhSection";
import Footer from "../../Footer/Footer";

import Section4 from "./Section4/Section4";
import ForthSection from "../../ForthSection/ForthSection";

import Section5 from "./Section5/Section5";
import ClientFormModal from "../../ClientFormModal";
import ProjectFormModal from "../../ProjectFormModal";
import ServicesPage from "../../ServicesPage/ServicesPage";
import IndustriesPage from "../../IndustriesPage/IndustriesPage";
import { clientModaState } from "../../Recoil";
import { useRecoilState } from "recoil";
import Section from "./Section1/Section";
import { Helmet } from "react-helmet";

function VuejsDevelopment() {
  const [showPopup, setShowPopup] = useState(false);
  const [open, setOpen] = useRecoilState(clientModaState);

  //popup
  const handleGetStartedClick = () => {
    setShowPopup(true);
  };
  return (
    <div className={style.main}>
      <Helmet>
        <title>Vue.JS Development Services</title>
        <meta charSet="utf-8" />
        <meta
          name="Vue.JS Development Services"
          content="Crafting dynamic web experiences with Vue.JS expertise. Elevate your digital presence seamlessly."
        />
        <link
          rel="canonical"
          href="https://www.easyryt.com/Vuejs-Development"
        />
      </Helmet>
      <NavBar />
      <div className={style.container}>
        <div>
          <h1>Vue JS Development Services</h1>
          <h6>
            Unleash the capabilities of your web applications with our
            state-of-the-art Vue.js Development Services
          </h6>
          <p>
            Greetings to EasyRyt’s Vue.js Development Services! As innovators in
            the digital sphere, we are experts in utilizing the potential of
            Vue.js to fabricate vibrant and engaging applications that connect
            with your desired users. Whether your objective is to construct a
            flexible one-page application or augment the performance of an
            ongoing project, our adept Vue.js professionals are at your service
            to manifest your creative concepts.
          </p>
          <button
            type="submit"
            className={style.submitBtn}
            onClick={() => setOpen(true)}
          >
            Get Started
          </button>
        </div>
        <div className={style.img_box}>
          <img
            className={style.img}
            src={img}
            alt="Vue.JS Development Services"
          />
        </div>
      </div>

      <ServicesPage />
      <IndustriesPage/>
      <Section2 />
      <Section3 />

   <ForthSection />
      <SixthSection />
      <EleventhSection />
      <Section5 />

      <ClientFormModal />
      <ProjectFormModal />

      <Footer />
    </div>
  );
}

export default VuejsDevelopment;
