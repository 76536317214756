import React from "react";
import { Container, Grid, Paper, Typography } from "@mui/material";
import styles from "./ServicesPage.module.css";

// Service Data
const services = [
  {
    title: "End-to-end Application Development",
    description:
      "Our expert team combines cutting-edge technologies and agile methodologies to ensure seamless development, ensuring clients receive efficient, scalable, and innovative applications to stay ahead in the competitive market.",
  },
  {
    title: "Application Services Assessment & Consulting",
    description:
      "Gain a competitive edge with our application services assessment and consulting. Our experienced team analyzes every aspect of your application, providing invaluable insights and actionable recommendations to elevate your performance and maximize your ROI.",
  },
  {
    title: "API Integration",
    description:
      "Seamlessly connect your applications to a world of possibilities with our API integrations. Our experts handle the technical complexities, allowing you to leverage the power of third-party services and platforms, unlocking endless growth opportunities.",
  },
  {
    title: "Flutter App Development",
    description:
      "We create innovative cross-platform apps using Flutter, so your business can reach both Android and iOS users with one streamlined solution. Our apps are designed to be fast, reliable, and deliver a smooth experience across all devices.",
  },
  {
    title: "Android App Development",
    description:
      "Our Android experts develop user-centric Android apps that are customized to fit your business needs. Our goal is to create apps that perform well and connect with users, reaching the broad Android audience effectively.",
  },
  {
    title: "Application Management",
    description:
      "Leave the hassle of application management to us. Our expert team ensures the smooth operation of your applications, keeping them running like clockwork while you focus on what truly matters - driving your business forward and exceeding customer expectations.",
  },
  {
    title: "User Interface Service",
    description:
      "Our talented designers create visually stunning interfaces that not only capture attention but also deliver seamless user experiences, making your applications irresistible and boosting customer satisfaction to new heights.",
  },
  {
    title: "Diverse Platforms",
    description:
      "Our expertise lies in developing applications that seamlessly integrate with diverse platforms, ensuring your business has a strong presence across multiple channels, and reaching customers wherever they are.",
  },
  {
    title: "iOS App Development",
    description:
      "Our team specializes in building next-gen high-quality iOS apps that meet Apple’s high standards. We focus on creating intuitive and engaging experiences that make your app stand out in the iOS market.",
  },
  {
    title: "Web App Development",
    description:
      "We design and build responsive web apps that work flawlessly across all browsers. Our web solutions are engineered to enhance your online presence by helping your business grow and thrive in the digital world.",
  },
];

const ServicesPage = () => {
  return (
    <div className={styles.pageContainer}>
      <Container>
        <h1>Application Development Services and Solutions We Offer</h1>
        <Typography variant="h5" align="center" color="gray" paragraph>
          Delivering exponential value to enterprises by highlighting their app
          across the globe.
        </Typography>
        <br/>
        <Grid container spacing={4}>
          {services.map((service, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Paper className={styles.serviceCard} elevation={5}>
                <Typography variant="h6" className={styles.serviceTitle}>
                  {service.title}
                </Typography>
                <Typography
                  variant="body2"
                  className={styles.serviceDescription}
                >
                  {service.description}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
};

export default ServicesPage;
