import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Accordion.css'; // Import the CSS file


export function Servicesaccordion() {
  return (
    <Accordion defaultActiveKey="0" className="dark-accordion">
      <Accordion.Item eventKey="0" className="dark-accordion-item">
        <Accordion.Header>Mobile App Development</Accordion.Header>
        <Accordion.Body className="dark-accordion-content">
          Mobile app development is the process of creating software
          applications specifically designed for use on mobile devices, such as
          smartphones and tablets. It involves the creation, design,
          development, testing, and deployment of applications that run on
          various mobile operating systems, including iOS and Android.
          <a
            href="https://en.wikipedia.org/wiki/Mobile_app_development"
            target="_blank"
            rel="noopener noreferrer"
          >
            <h6>Read More ᐳ</h6>
          </a>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="1" className="dark-accordion-item">
        <Accordion.Header>Progressive Web App</Accordion.Header>
        <Accordion.Body className="dark-accordion-content">
          Progressive Web Apps (PWAs) are web-based applications that offer a
          native app-like experience, combining the reach of the web with the
          functionality of mobile apps.
          <a
            href="https://en.wikipedia.org/wiki/Progressive_Web_Apps"
            target="_blank"
            rel="noopener noreferrer"
          >
            <h6>Read More ᐳ</h6>
          </a>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="2" className="dark-accordion-item">
        <Accordion.Header>Enterprise Application Development</Accordion.Header>
        <Accordion.Body className="dark-accordion-content">
          Enterprise Application Development refers to the process of creating
          software applications specifically designed for large organizations or
          businesses. These applications are developed to meet the unique
          requirements and challenges of enterprise environments, focusing on
          scalability, security, and integration with existing systems.
          <a
            href="https://www.silvertouch.com/blog/enterprise-application-development-how-it-drives-growth-for-business/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <h6>Read More ᐳ</h6>
          </a>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="3" className="dark-accordion-item">
        <Accordion.Header>Design UI/UX</Accordion.Header>
        <Accordion.Body className="dark-accordion-content">
          UI/UX design aims to create a positive user experience that encourages
          customers to stick with a brand or product. While a UX Designer
          decides how the user interface works, the UI designer decides how the
          user interface looks.
          <a
            href="https://careerfoundry.com/en/blog/ux-design/the-difference-between-ux-and-ui-design-a-laymans-guide/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <h6>Read More ᐳ</h6>
          </a>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="4" className="dark-accordion-item">
        <Accordion.Header>Digital Marketing</Accordion.Header>
        <Accordion.Body className="dark-accordion-content">
          Digital marketing encompasses various online strategies and techniques
          to promote products, services, or brands using digital channels. It
          includes activities such as search engine optimization (SEO), social
          media marketing, content marketing, email marketing, pay-per-click
          advertising, and more.
          <a
            href="https://en.wikipedia.org/wiki/Digital_marketing"
            target="_blank"
            rel="noopener noreferrer"
          >
            <h6>Read More ᐳ</h6>
          </a>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="5" className="dark-accordion-item">
        <Accordion.Header>Product Management</Accordion.Header>
        <Accordion.Body className="dark-accordion-content">
          Product management is the process of overseeing the development,
          launch, and ongoing management of a product or service throughout its
          lifecycle. It involves strategic planning, market research, defining
          product vision, gathering requirements, and collaborating with
          cross-functional teams to ensure successful product development.
          Product managers analyze market trends, competition, and customer
          needs to make informed decisions regarding product features, pricing,
          and positioning.
          <a
            href="https://en.wikipedia.org/wiki/Product_management"
            target="_blank"
            rel="noopener noreferrer"
          >
            <h6>Read More ᐳ</h6>
          </a>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="6" className="dark-accordion-item">
        <Accordion.Header>Sap（system Application Product）</Accordion.Header>
        <Accordion.Body className="dark-accordion-content">
          SAP, which stands for System Application Product, is a leading
          enterprise software company that offers a range of business software
          solutions. SAP's software applications help organizations manage
          various aspects of their operations, including finance, human
          resources, supply chain, customer relationship management, and more.
          <a
            href="https://www.engineersgarage.com/sap-systems-applications-products-in-data-processing/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <h6>Read More ᐳ</h6>
          </a>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export function ClientServicesaccordian() {
  return (
<Accordion defaultActiveKey="0" className="dark-accordion">
      <Accordion.Item eventKey="1" className="dark-accordion-item">
        <Accordion.Header>Q. What services does your IT company offer?</Accordion.Header>
        <Accordion.Body className="dark-accordion-content">
          We offer a wide range of services, including app development, website
          development, digital marketing, WordPress development, cloud
          solutions, and UI/UX design.
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="2" className="dark-accordion-item">
        <Accordion.Header>Q. How do you ensure the security of the apps and websites you develop?</Accordion.Header>
        <Accordion.Body className="dark-accordion-content">
          We prioritize security at every step of the development process,
          implementing industry best practices and using the latest security
          measures to safeguard your app or website from potential threats.
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="3" className="dark-accordion-item">
        <Accordion.Header>Q. How long does it typically take to develop a custom app or website?</Accordion.Header>
        <Accordion.Body className="dark-accordion-content">
          The development timeline varies depending on the project's complexity
          and requirements. We'll provide you with a detailed estimate after
          understanding your project's scope.
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="4" className="dark-accordion-item">
        <Accordion.Header>Q. What are the benefits of using cloud services for my business?</Accordion.Header>
        <Accordion.Body className="dark-accordion-content">
          Cloud services offer advantages such as cost savings, scalability,
          accessibility, and improved collaboration among team members.
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="5" className="dark-accordion-item">
        <Accordion.Header>Q. Do you provide content creation services for websites and apps?</Accordion.Header>
        <Accordion.Body className="dark-accordion-content">
          Yes, we offer content creation services, including copywriting,
          graphic design, and multimedia content production, to enhance the
          overall user experience of your digital products.
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="6" className="dark-accordion-item">
        <Accordion.Header>Q. What makes your WordPress development services stand out?</Accordion.Header>
        <Accordion.Body className="dark-accordion-content">
          Our WordPress development services are distinguished by our team's
          expertise in creating highly functional, visually appealing, and
          user-friendly websites using the WordPress platform.
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}
