import React, { useState } from "react";
import style from "./DatabaseDesignandManagement.module.css";
import NavBar from "../../NavBar";
import img from "../../Image/Database Design and Management, 1.png";
import Section2 from "./Section2/Section2";
import Section3 from "./Section3/Section3";
import SixthSection from "../../SixthSection/SixthSection";
import EleventhSection from "../../EleventhSection/EleventhSection";
import Footer from "../../Footer/Footer";

import Section4 from "./Section4/Section4";
import ForthSection from "../../ForthSection/ForthSection";

import Section5 from "./Section5/Section5";
import ClientFormModal from "../../ClientFormModal";
import ProjectFormModal from "../../ProjectFormModal";
import ServicesPage from "../../ServicesPage/ServicesPage";
import IndustriesPage from "../../IndustriesPage/IndustriesPage";
import { clientModaState } from "../../Recoil";
import { useRecoilState } from "recoil";
import Section from "./Section1/Section";
import { Helmet } from "react-helmet";

function DatabaseDesignandManagement() {
  const [showPopup, setShowPopup] = useState(false);
  const [open, setOpen] = useRecoilState(clientModaState);

  //popup
  const handleGetStartedClick = () => {
    setShowPopup(true);
  };
  return (
    <div className={style.main}>
      <Helmet>
        <title>Database Design & Management</title>
        <meta charSet="utf-8" />
        <meta
          name="Database Design & Management"
          content="Expert services for optimized database solutions. Elevate performance and efficiency seamlessly."
        />
        <link
          rel="canonical"
          href="https://www.easyryt.com/Database-Design-and-Management"
        />
      </Helmet>

      <NavBar />
      <div className={style.container}>
        <div>
          <h1>Database Design and Management Services</h1>
          <h6>Discover Our Extensive Array of AWS Solutions</h6>
          <p>
            Welcome to EasyRyt’s exclusive page for Professional Database
            Creation and Administration Services. On this dedicated platform, we
            merge cutting-edge techniques with streamlined data structure to
            provide you with unmatched database solutions. Are you ready to
            enhance the way you store, retrieve, and handle your valuable
            information? Your search ends here. Our team of skilled database
            professionals is ready to revolutionize your data needs, offering
            you state-of-the-art, flexible, and well-arranged database solutions
            that redefine productivity and enhance your decision-making process.
          </p>
          <button
            type="submit"
            className={style.submitBtn}
            onClick={() => setOpen(true)}
          >
            Get Started
          </button>
        </div>
        <div className={style.img_box}>
          <img
            className={style.img}
            src={img}
            alt="Database Design and Management Services"
          />
        </div>
      </div>

      <ServicesPage />
      <IndustriesPage/>
      <Section2 />
      <Section3 />

   <ForthSection />
      <SixthSection />
      <EleventhSection />
      <Section5 />

      <ClientFormModal />
      <ProjectFormModal />

      <Footer />
    </div>
  );
}

export default DatabaseDesignandManagement;
