import React, { useState } from "react";
import style from "./IOSApplicationDevelopmentServices.module.css";
import NavBar from "../../NavBar";
import img from "../../Image/ios Application Development Services 1.png";
import Section2 from "./Section2/Section2";
import Section3 from "./Section3/Section3";
import SixthSection from "../../SixthSection/SixthSection";
import EleventhSection from "../../EleventhSection/EleventhSection";
import Footer from "../../Footer/Footer";

import Section4 from "./Section4/Section4";
import ForthSection from "../../ForthSection/ForthSection";

import Section5 from "./Section5/Section5";
import ClientFormModal from "../../ClientFormModal";
import ProjectFormModal from "../../ProjectFormModal";
import ServicesPage from "../../ServicesPage/ServicesPage";
import IndustriesPage from "../../IndustriesPage/IndustriesPage";
import { clientModaState } from "../../Recoil";
import { useRecoilState } from "recoil";
import Section from "./Section1/Section";
import { Helmet } from "react-helmet";

function IOSApplicationDevelopmentServices() {
  const [showPopup, setShowPopup] = useState(false);
  const [open, setOpen] = useRecoilState(clientModaState);

  //popup
  const handleGetStartedClick = () => {
    setShowPopup(true);
  };
  return (
    <div className={style.main}>
      <Helmet>
        <title>Pro iOS App Development</title>
        <meta charSet="utf-8" />
        <meta
          name="Pro iOS App Development"
          content="Elevate your business with our premier iOS app development services. Delivering excellence through innovation."
        />
        <link
          rel="canonical"
          href="https://www.easyryt.com/IOS-Application-Development-Services"
        />
      </Helmet>
      <NavBar />
      <div className={style.container}>
        <div>
          <h1>IOS Application Development Services</h1>
          <h6>Crafting Revolutionary Mobile Experiences</h6>
          <p>
            In the ever-evolving realm of cell generation, iOS remains a
            frontrunner with its dedication to innovation and consumer
            experience. As a business, having a presence on the iOS platform can
            open doorways to a huge and engaged target market. Our iOS app
            development services are designed to transform your ideas into
            stylish and useful packages that captivate users and force
            enterprise fulfilment.
          </p>
          <button
            type="submit"
            className={style.submitBtn}
            onClick={() => setOpen(true)}
          >
            Get Started
          </button>
        </div>
        <div>
          <img
            className={style.img}
            src={img}
            alt="IOS Application Development Services"
          />
        </div>
      </div>

      <ServicesPage />
      <IndustriesPage/>
      <Section2 />
      <Section3 />

   <ForthSection />
      <SixthSection />
      <EleventhSection />
      <Section5 />

      <ClientFormModal />
      <ProjectFormModal />

      <Footer />
    </div>
  );
}

export default IOSApplicationDevelopmentServices;
