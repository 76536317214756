import React from "react";
import style from "./Welcome.module.css";
import EarthCanvas from "./Earth";
import { Button } from "@mui/material";
import ClientFormModal from "./ClientFormModal";
import ProjectFormModal from "./ProjectFormModal";
import { useRecoilState } from "recoil";
import { clientDataState, clientModaState, projectModalState } from "./Recoil";
import image3d from "../Components/Image/3d.png"

const WelcomePage = () => {
  const [open, setOpen] = useRecoilState(clientModaState);
  const [isProjectModalOpen, setProjectModalOpen] = useRecoilState(projectModalState);
  const [clientData] = useRecoilState(clientDataState);

  return (
    <div className={style.welcome_container}>
      <div className={style.welcome_text}>
        <h1>Smart Digital Solutions for Growing SMEs & MSMEs</h1>
        <p className={style.subtext}>
          Empowering your business with cutting-edge digital technology for success in a fast-paced world.
        </p>
        <Button
          variant="contained"
          className={style.query_button}
          onClick={() => setOpen(true)}
        >
          Send A Query
        </Button>
        {/* Client Form Modal */}
        <ClientFormModal /><ProjectFormModal />
        {/* Project Form Modal */}
        <ProjectFormModal
          open={isProjectModalOpen}
          handleClose={() => setProjectModalOpen(false)}
          clientData={clientData} // Passing pre-filled data
        />
      </div>

      {/* Earth Canvas section */}
      <div className={style.welcome_canvas}>
       <img src={image3d} className={style.img} />
      </div>
    </div>
  );
};

export default WelcomePage;
