import React, { useState } from "react";
import style from "./EcommerceWebsiteOnlineStore.module.css";
import NavBar from "../../NavBar";
import img from "../../Image/Ecommerce Online Store 1.png";
import Section2 from "./Section2/Section2";
import Section3 from "./Section3/Section3";
import SixthSection from "../../SixthSection/SixthSection";
import EleventhSection from "../../EleventhSection/EleventhSection";
import Footer from "../../Footer/Footer";

import Section4 from "./Section4/Section4";
import ForthSection from "../../ForthSection/ForthSection";

import Section5 from "./Section5/Section5";
import ClientFormModal from "../../ClientFormModal";
import ProjectFormModal from "../../ProjectFormModal";
import ServicesPage from "../../ServicesPage/ServicesPage";
import IndustriesPage from "../../IndustriesPage/IndustriesPage";
import { clientModaState } from "../../Recoil";
import { useRecoilState } from "recoil";
import Section from "./Section1/Section";
import { Helmet } from "react-helmet";

function EcommerceWebsiteOnlineStore() {
  const [showPopup, setShowPopup] = useState(false);
  const [open, setOpen] = useRecoilState(clientModaState);

  //popup
  const handleGetStartedClick = () => {
    setShowPopup(true);
  };
  return (
    <div className={style.main}>
      <Helmet>
        <title>WordPress Ecommerce Expertise</title>
        <meta charSet="utf-8" />
        <meta
          name="WordPress Ecommerce Expertise"
          content="Elevate sales with tailored WordPress ecommerce website development. Build your online store with confidence."
        />
        <link
          rel="canonical"
          href="https://www.easyryt.com/E-commerce-Website-Online-Store"
        />
      </Helmet>
      <NavBar />
      <div className={style.container}>
        <div>
          <h1>WordPress eCommerce Website Development</h1>
          <h6>
            Unlock the Potential of your Enterprise with Development of
            WordPress ECommerce Websites
          </h6>
          <p>
            We invite you to explore, the exclusive services dedicated to
            WordPress ECommerce Website Development. At EasyRyt, we utilize the
            capabilities of WordPress, WooCommerce, and our refined knowledge to
            construct captivating online shops that leave a lasting impact.
            Through the perfect fusion of cutting-edge technology, innovative
            design, and user-friendly interfaces, we bring your ECommerce
            aspirations to life, fuelling profitability and expansion.
          </p>
          <button
            type="submit"
            className={style.submitBtn}
            onClick={() => setOpen(true)}
          >
            Get Started
          </button>
        </div>
        <div className={style.img_box}>
          <img
            className={style.img}
            src={img}
            alt="WordPress eCommerce Website Development"
          />
        </div>
      </div>

      <ServicesPage />
      <IndustriesPage/>
      <Section2 />
      <Section3 />

   <ForthSection />
      <SixthSection />
      <EleventhSection />
      <Section5 />

      <ClientFormModal />
      <ProjectFormModal />

      <Footer />
    </div>
  );
}

export default EcommerceWebsiteOnlineStore;
