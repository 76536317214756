import React, { useState } from "react";
import style from "./AngularDvelopment.module.css";
import NavBar from "../../NavBar";
import img from "../../Image/Angular Dvelopment 1.png";
import Section2 from "../FirstPage/Section2/Section2";
import Section3 from "../FirstPage/Section3/Section3";
import TenthSection from "../../TenthSection/TenthSection";
import SixthSection from "../../SixthSection/SixthSection";
import EleventhSection from "../../EleventhSection/EleventhSection";
import Footer from "../../Footer/Footer";

import Section from "./Section1/Section";
import Section5 from "./Section5/Section5";
import ClientFormModal from "../../ClientFormModal";
import ProjectFormModal from "../../ProjectFormModal";
import ServicesPage from "../../ServicesPage/ServicesPage";
import IndustriesPage from "../../IndustriesPage/IndustriesPage";
import { clientModaState } from "../../Recoil";
import { useRecoilState } from "recoil";
import { Helmet } from "react-helmet";

function AngularDvelopment() {
  const [showPopup, setShowPopup] = useState(false);
  const [open, setOpen] = useRecoilState(clientModaState);

  const handleGetStartedClick = () => {
    setShowPopup(true);
  };
  return (
    <div className={style.main}>
      <Helmet>
        <title>AngularJS Development Services</title>
        <meta charSet="utf-8" />
        <meta
          name="AngularJS Development Services"
          content="Crafting dynamic web experiences with AngularJS expertise. Elevate your digital presence seamlessly."
        />
        <link
          rel="canonical"
          href="https://www.easyryt.com/Angular-Dvelopment"
        />
      </Helmet>

      <NavBar />
      <div className={style.container}>
        <div>
          <h1>Angular JS Development Service</h1>
          <p>
            Welcome to EasyRyt’s AngularJS Development Solutions Explore the
            possibilities of state-of-the-art web applications with our
            specialized AngularJS Software Development Solutions. As a reputable
            AngularJS development firm, we stand out in leveraging the
            capabilities of this strong framework to develop vibrant and
            engaging applications that connect with your target audience.
            Whether you aim to enhance an ongoing project or create a brand new
            one, our team of skilled AngularJS professionals is available to
            transform your concept into reality.
          </p>
          <button
            type="submit"
            className={style.submitBtn}
            onClick={() => setOpen(true)}
          >
            Get Started
          </button>
        </div>
        <div>
          <img
            className={style.img}
            src={img}
            alt="AngularJS Development Services"
          />
        </div>
      </div>

      <ServicesPage />
      <IndustriesPage/>
      <Section2 />
      <Section3 />
      <TenthSection />
      <SixthSection />
      <EleventhSection />
      <Section5 />

      <Footer />
      {/* Popup window */}
    </div>
  );
}

export default AngularDvelopment;
