import React, { useState } from "react";
import style from "./AppDevelopment.module.css";
import NavBar from "../../NavBar";
import img from "../../Image/Angular Dvelopment 1.png";
import Section1 from "./Section1/Section1";
import Section2 from "../FirstPage/Section2/Section2";
import Section3 from "../FirstPage/Section3/Section3";
import TenthSection from "../../TenthSection/TenthSection";
import SixthSection from "../../SixthSection/SixthSection";
import EleventhSection from "../../EleventhSection/EleventhSection";
import Footer from "../../Footer/Footer";
import { clientModaState } from "../../Recoil";
import { useRecoilState } from "recoil";
import { Helmet } from "react-helmet";
import ServicesPage from "../../ServicesPage/ServicesPage";
import IndustriesPage from "../../IndustriesPage/IndustriesPage";

function AppDevelopment() {
  const [showPopup, setShowPopup] = useState(false);
  const [open, setOpen] = useRecoilState(clientModaState);

  const handleGetStartedClick = () => {
    setShowPopup(true);
  };
  return (
    <div className={style.main}>
      <Helmet>
        <title>Expert App Development Solutions - EasyRyt</title>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Transform your ideas into innovative mobile and web applications. EasyRyt offers end-to-end app development services for robust and scalable solutions."
        />
        <link
          rel="canonical"
          href="https://www.easyryt.com/app-development-solutions"
        />
      </Helmet>
      <NavBar />
      <div className={style.container}>
        <div>
          <h1>App Development Services</h1>
          <p>
            App development is the process of creating software applications for
            mobile devices like smartphones and tablets. This involves
            designing, coding, testing, and deploying the app to platforms like
            iOS or Android.
          </p>
          <button
            type="submit"
            className={style.submitBtn}
            onClick={() => setOpen(true)}
          >
            Get Started
          </button>
        </div>
        <div>
          <img className={style.img} src={img} alt="App Development Services" />
        </div>
      </div>

      <ServicesPage />
      <IndustriesPage />
      <Section2 />
      <Section3 />
      <TenthSection />
      <SixthSection />
      <EleventhSection />

      <Footer />
      {/* Popup window */}
    </div>
  );
}

export default AppDevelopment;
