import React from 'react';
import {
  Modal,
  Box,
  Typography,
  List,
  ListItem,
  ListItemButton,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom'; // Assuming you use React Router for navigation

// Styles for the modal
const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  bgcolor: '#1e1e1e', // Dark background color
  color: '#f0f0f0', // Light color for text
  boxShadow: 24,
  p: 3,
  borderRadius: '8px',
  maxHeight: '80vh',
  overflowY: 'auto',
};

 // Service Categories
 const serviceCategories = [
  {
    title: "Mobile App Development",
    services: [
      { name: "Mobile App Development", path: "/Mobile-App-Development" },
      { name: "Native Mobile App Development", path: "/Native-Mobile-App-Development" },
      { name: "Cross-Platform App Development", path: "/Cross-Platform-App-Development" },
      { name: "Android Application Development Services", path: "/Android-Application-Development-Services" },
      { name: "IOS Application Development Services", path: "/IOS-Application-Development-Services" },
      { name: "Hybrid Application Development Services", path: "/Hybrid-Application-Development-Services" },
      { name: "App Backend Development", path: "/App-Backend-Development" },
      { name: "Flutter App Development", path: "/Flutter-App-Development" },
      { name: "React Native App Development", path: "/React-Native-App-Development" },
      { name: "Mobile App Testing and Testing Quality Assurance", path: "/Mobile-App-Testing-and-Testing-Quality-Assurance" },
    ],
  },
  {
    title: "Digital Marketing",
    services: [
      { name: "Search Engine Optimization (SEO)", path: "/Search-Engine-Optimization" },
      { name: "Pay-Per-Click (PPC) Advertising", path: "/Pay-Per-Click-Advertising" },
      { name: "Social Media Advertising", path: "/Social-Media-Advertising" },
      { name: "Google Advertising", path: "/Google-Advertising" },
      { name: "Facebook & Instagram Advertising", path: "/Facebook-&-Instagram-Advertising" },
      { name: "Content Marketing", path: "/Content-Marketing" },
      { name: "Social Media Marketing", path: "/Social-Media-Marketing" },
      { name: "Email Marketing", path: "/Email-Marketing" },
      { name: "Analytics and Reporting", path: "/Analytics-and-Reporting" },
      { name: "Website Traffic Advertising", path: "/Website-Traffic-Advertising" },
      { name: "E-Commerce Advertising", path: "/E-Commerce-Advertising" },
      { name: "App Install Advertising", path: "/App-Install-Advertising" },
      { name: "Android App Advertising", path: "/Android-App-Advertising" },
      { name: "IOS App Advertising", path: "/IOS-App-Advertising" },
    ],
  },
  {
    title: "Website Development",
    services: [
      { name: "E-Commerce Development", path: "/E-Commerce-Development" },
      { name: "Front-End Development", path: "/Front-End-Development" },
      { name: "Backend Development", path: "/Backend-Development" },
      { name: "Full Stack Development Services", path: "/FullStack-Development-Services" },
      { name: "Blog or Personal Website", path: "/Blog-or-Personal-Website" },
      { name: "Business Website", path: "/Business-Website" },
      { name: "Ecommerce Website / Online Store", path: "/E-commerce-Website-Online-Store" },
      { name: "Online Courses", path: "/Online-Courses" },
      { name: "Podcast Website", path: "/Podcast-Website" },
      { name: "Affiliate Website", path: "/Affiliate-Website" },
      { name: "Job Board Website", path: "/JobBoard-Website" },
      { name: "Portfolio Websites", path: "/Portfolio-Websites" },
      { name: "Travel Website", path: "/Travel-Website" },
      { name: "Fashion / Lifestyle Website", path: "/Fashion-Life-style-Website" },
    ],
  },
  {
    title: "UI/UX Design",
    services: [
      { name: "Mobile App UI / UX Design Services", path: "/Mobile-App-UIUX-Design-Services" },
      { name: "Android App UI / UX Design Services", path: "/Android-App-UIUX-Design-Services" },
      { name: "UI / UX Design Services", path: "/UIUX-Design-Services" },
      { name: "ISO UI / UX Design Services", path: "/ISO-UIUX-Design-Services" },
      { name: "Website UI / UX Design Services", path: "/Website-UIUX-Design-Services" },
    ],
  },
  {
    title: "Frontend Development",
    services: [
      { name: "React Js Development", path: "/ReactJs-Development" },
      { name: "Next js Development", path: "/Nextjs-Development" },
      { name: "Vue js Development", path: "/Vuejs-Development" },
      { name: "Angular Development", path: "/Angular-Dvelopment" },
      { name: "React Native Development", path: "/ReactNative-Development" },
    ],
  },
  {
    title: "Backend Development",
    services: [
      { name: "Node js", path: "/Nodejs" },
      { name: "Laravel", path: "/Laravel" },
      { name: "Python", path: "/Python" },
      { name: "API Development", path: "/API-Development" },
      { name: "Database Design and Management", path: "/Database-Designand-Management" },
      { name: "Backend Framework Development", path: "/Backend-Framework-Development" },
      { name: "Web Application Development", path: "/Web-Application-Development" },
      { name: "Mobile Backend Development", path: "/Mobile-Backend-Development" },
      { name: "Real-Time Data Processing", path: "/Real-TimeData-Processing" },
      { name: "Performance Optimization", path: "/Performance-Optimization" },
      { name: "Integration with Third-Party Services", path: "/Integration-with-Third-Party-Services" },
    ],
  },
  {
    title: "Cloud",
    services: [
      { name: "Azure", path: "/Azure" },
      { name: "Laravel", path: "/Aws" },
      { name: "Google Cloud", path: "/Google-Cloud" },
    ],
  },
];


const ServiceModal = ({ open, onClose }) => {
  const navigate = useNavigate();

  // Handle service click event
  const handleServiceClick = (path) => {
    navigate(path); // Navigate to the desired path
    onClose(); // Close the modal after navigation
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={modalStyle}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="h6" sx={{ color: '#f0f0f0' }}>Our Services</Typography>
          <IconButton onClick={onClose} sx={{ color: '#f0f0f0' }}>
            <CloseIcon />
          </IconButton>
        </Box>

        {/* List of categories and services */}
        {serviceCategories.map((category, index) => (
          <Box key={index} mb={3}>
            <Typography variant="h6" sx={{ mb: 1, color: '#b0b0b0' }}>
              {category.title}
            </Typography>
            <List>
              {category.services.map((service, serviceIndex) => (
                <ListItem key={serviceIndex} disablePadding>
                  <ListItemButton
                    onClick={() => handleServiceClick(service.path)}
                    sx={{
                      color: '#e0e0e0',
                      '&:hover': {
                        bgcolor: '#333333', // Darker hover background
                        color: '#ffffff',
                      },
                    }}
                  >
                    {service.name}
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        ))}
      </Box>
    </Modal>
  );
};

export default ServiceModal;
