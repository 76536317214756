import React, { useState } from "react";
import style from "./AndroidAppUIUXDesignServices.module.css";
import NavBar from "../../NavBar";
import img from "../../Image/Android App Advertising 1.png";
import Section2 from "./Section2/Section2";
import Section3 from "./Section3/Section3";
import SixthSection from "../../SixthSection/SixthSection";
import EleventhSection from "../../EleventhSection/EleventhSection";
import Footer from "../../Footer/Footer";

import Section4 from "./Section4/Section4";
import ForthSection from "../../ForthSection/ForthSection";

import Section5 from "./Section5/Section5";
import ClientFormModal from "../../ClientFormModal";
import ProjectFormModal from "../../ProjectFormModal";
import ServicesPage from "../../ServicesPage/ServicesPage";
import IndustriesPage from "../../IndustriesPage/IndustriesPage";
import { clientModaState } from "../../Recoil";
import { useRecoilState } from "recoil";
import Section from "./Section1/Section";
import { Helmet } from "react-helmet";

function AndroidAppUIUXDesignServices() {
  const [showPopup, setShowPopup] = useState(false);
  const [open, setOpen] = useRecoilState(clientModaState);

  //popup
  const handleGetStartedClick = () => {
    setShowPopup(true);
  };
  return (
    <div className={style.main}>
      <Helmet>
        <title>Android App Design Services</title>
        <meta charSet="utf-8" />
        <meta
          name="Android App Design Services"
          content="Crafting stellar UI/UX for standout Android apps. Elevate user experiences with expert design services."
        />
        <link
          rel="canonical"
          href="https://www.easyryt.com/Android-App-UIUX-Design-Services"
        />
      </Helmet>

      <NavBar />
      <div className={style.container}>
        <div>
          <h1>Android App UI/UX Design Services</h1>
          <h6>Crafting Revolutionary Mobile Experiences</h6>
          <p>
            Welcome to EasyRyt’s top-notch Android Application Interface and
            Experience Design services! In today's ever-evolving digital
            landscape, ensuring a flawless, visually stunning, and intuitive
            interface holds the key to triumph in the realm of Android
            applications. Our team of expert designers, who specialize in
            crafting extraordinary interfaces, are here to engage users and
            optimize their interaction with your app for unrivalled success..
          </p>
          <button
            type="submit"
            className={style.submitBtn}
            onClick={() => setOpen(true)}
          >
            Get Started
          </button>
        </div>
        <div className={style.img_box}>
          <img
            className={style.img}
            src={img}
            alt="Android App Design Services"
          />
        </div>
      </div>

      <ServicesPage />
      <IndustriesPage/>
      <Section2 />
      <Section3 />

   <ForthSection />
      <SixthSection />
      <EleventhSection />
      <Section5 />

      <ClientFormModal />
      <ProjectFormModal />

      <Footer />
    </div>
  );
}

export default AndroidAppUIUXDesignServices;
