import React from "react";
import style from "./AboutUs.module.css";
import NavBar from "../../NavBar";
import img from "../../Image/Website Traffic Advertising 2.png";
import TenthSection from "../../TenthSection/TenthSection";
import SixthSection from "../../SixthSection/SixthSection";
import EleventhSection from "../../EleventhSection/EleventhSection";
import Footer from "../../Footer/Footer";
import ClientFormModal from "../../ClientFormModal";
import ProjectFormModal from "../../ProjectFormModal";
import ServicesPage from "../../ServicesPage/ServicesPage";
import IndustriesPage from "../../IndustriesPage/IndustriesPage";
import { clientModaState } from "../../Recoil";
import { useRecoilState } from "recoil";
import { Helmet } from "react-helmet";

function AboutUs() {
  return (
    <div className={style.main}>
      <Helmet>
        <title>About Us - EasyRyt</title>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="We Transform Digital Solutions"
        />
        <link rel="canonical" href="https://www.easyryt.com/About-Us" />
      </Helmet>
      <NavBar />
      <div className={style.container}>
        <div className={style.textContainer}>
          <h1 className={style.title}>We Transform Digital Solutions</h1>
          <p className={style.description}>
            Easyryt Software Solutions offers the tools you require to
            revolutionize & expedite your business. We stand as a prominent
            software development enterprise with over a decade of experience in
            crafting cutting-edge resolutions.
          </p>
          {/* <button className={style.submitBtn}>Learn More</button> */}
        </div>
        <div className={style.imageContainer}>
          <img className={style.img} src={img} alt="About" />
        </div>
      </div>
      <TenthSection />
      <SixthSection />
      <EleventhSection />
      <ClientFormModal />
      <ProjectFormModal />
      <ProjectFormModal />
      <Footer />
    </div>
  );
}

export default AboutUs;
