import React, { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  Button,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Snackbar,
} from "@mui/material";
import { ArrowForward } from "@mui/icons-material";
import MuiAlert from "@mui/material/Alert";
import axios from "axios";
import styles from "./AttractForm.module.css"; // Custom styles for dark theme
import { clientDataState, projectModalState } from "./Recoil";
import { useSetRecoilState } from "recoil";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const AttractForm = () => {
  const [name, setFullName] = useState("");
  const [phone, setMobileNumber] = useState("");
  const [requestServices, setRequestServices] = useState("");
  const [email, setEmail] = useState("");
  const [requestServicesData, setRequestServicesData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState(false);
  const setModalOpen = useSetRecoilState(projectModalState);
  const setClientData = useSetRecoilState(clientDataState);

  useEffect(() => {
    setLoading(true);
    axios
      .get("https://backend.easyryt.com/client/allServices")
      .then((response) => {
        setRequestServicesData(response.data.data || []);
        setLoading(false);
      })
      .catch((err) => {
        setError("Failed to load services");
        setLoading(false);
      });
  }, []);

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const validateName = (name) => {
    return name.length > 1 && /^[a-zA-Z\s]*$/.test(name);
  };

  const handleSubmit = () => {
    if (!validateName(name)) {
      setError("Please enter a valid name.");
      return;
    }

    if (!validateEmail(email)) {
      setError("Please enter a valid email address.");
      return;
    }

    if (!phone) {
      setError("Please enter a valid phone number.");
      return;
    }

    setSubmitLoading(true);
    setError("");

    const clientData = { name, phone, requestServices, email };

    axios
      .post("https://backend.easyryt.com/client/clientInfo", clientData)
      .then(() => {
        setClientData(clientData); // Save client data to state
        setSubmitLoading(false);
        setModalOpen(true); // Open the Project Modal
        setFullName("");
        setMobileNumber("");
        setRequestServices("");
        setEmail("");
        setSuccessMessage(true);
      })
      .catch(() => {
        setError("Form submission failed. Please try again.");
        setSubmitLoading(false);
      });
  };

  return (
    <div className={styles.container}>
      <Box className={styles.attractiveBox}>
        <Typography variant="h4" className={styles.heading}>
          Your Dream Project Starts Here!
        </Typography>
        <Typography variant="body1" className={styles.subheading}>
          Fill out the form below to get started.
        </Typography>

        <Grid container spacing={2} className={styles.formGrid}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Full Name *"
              value={name}
              onChange={(e) => setFullName(e.target.value)}
              fullWidth
              variant="outlined"
              className={styles.inputField}
              InputProps={{
                style: { color: "#ffffff" },
              }}
              InputLabelProps={{
                style: { color: "#ffffff" },
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <PhoneInput
              country={"us"}
              value={phone}
              onChange={(phone) => setMobileNumber(phone)}
              inputStyle={{
                width: "100%",
                height:"50px",
                background: "#333",
                color: "#ffffff",
              }}
              buttonStyle={{ background: "#333" }}
              dropdownStyle={{ background: "#444", color: "#ffffff" }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Email *"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
              variant="outlined"
              className={styles.inputField}
              InputProps={{
                style: { color: "#ffffff" },
              }}
              InputLabelProps={{
                style: { color: "#ffffff" },
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="outlined" className={styles.inputField}>
              <InputLabel style={{ color: "#ffffff" }}>Request Service</InputLabel>
              <Select
                value={requestServices}
                onChange={(e) => setRequestServices(e.target.value)}
                label="Request Service *"
                sx={{
                  color: "#ffffff",
                  ".MuiOutlinedInput-notchedOutline": { borderColor: "#ffffff" },
                  ".MuiSvgIcon-root": { color: "#ffffff" },
                }}
              >
                {requestServicesData.map((service) => (
                  <MenuItem key={service._id} value={service.requestServices}>
                    {service.requestServices}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {error && (
            <Grid item xs={12}>
              <Typography color="error" className={styles.errorText}>
                {error}
              </Typography>
            </Grid>
          )}

          <Grid item xs={12}>
            <Button
              variant="contained"
              onClick={handleSubmit}
              disabled={submitLoading}
              className={styles.submitButton}
              endIcon={<ArrowForward />}
            >
              {submitLoading ? "Submitting..." : "Submit Form"}
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Snackbar
        open={successMessage}
        autoHideDuration={6000}
        onClose={() => setSuccessMessage(false)}
      >
        <MuiAlert onClose={() => setSuccessMessage(false)} severity="success">
          Form submitted successfully!
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default AttractForm;
