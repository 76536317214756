import React, { useState } from "react";
import style from "./AndroidAppAdvertising.module.css";
import NavBar from "../../NavBar";
import img from "../../Image/Android App Advertising 1.png";
import Section2 from "./Section2/Section2";
import Section3 from "./Section3/Section3";
import SixthSection from "../../SixthSection/SixthSection";
import EleventhSection from "../../EleventhSection/EleventhSection";
import Footer from "../../Footer/Footer";

import Section4 from "./Section4/Section4";
import ForthSection from "../../ForthSection/ForthSection";

import Section5 from "./Section5/Section5";
import ClientFormModal from "../../ClientFormModal";
import ProjectFormModal from "../../ProjectFormModal";
import ServicesPage from "../../ServicesPage/ServicesPage";
import IndustriesPage from "../../IndustriesPage/IndustriesPage";
import { clientModaState } from "../../Recoil";
import { useRecoilState } from "recoil";
import Section from "./Section1/Section";
import { Helmet } from "react-helmet";

function AndroidAppAdvertising() {
  const [showPopup, setShowPopup] = useState(false);
  const [open, setOpen] = useRecoilState(clientModaState);

  //popup
  const handleGetStartedClick = () => {
    setShowPopup(true);
  };
  return (
    <div className={style.main}>
      <Helmet>
        <title>Powerful Android App Ads</title>
        <meta charSet="utf-8" />
        <meta
          name="Powerful Android App Ads"
          content="Amplify visibility and installs with expert Android app advertising. Elevate your app's success."
        />
        <link
          rel="canonical"
          href="https://www.easyryt.com/Android-App-Advertising"
        />
      </Helmet>

      <NavBar />
      <div className={style.container}>
        <div>
          <h1>Android App Advertising</h1>
          <h6>Crafting Revolutionary Mobile Experiences</h6>
          <p>
            EasyRyt, the ultimate platform for extensive and outcome-oriented
            app promotion solutions. As an expert app marketing agency, we are
            dedicated to accelerating the growth and exposure of your mobile
            application in the ever-evolving digital world. With a deep
            comprehension of the complexities associated with app promotion and
            mobile app marketing, we devise customized tactics that align with
            your app's distinctive character, guaranteeing its reach to the
            appropriate user base and facilitating extraordinary
            accomplishments.
          </p>
          <button
            type="submit"
            className={style.submitBtn}
            onClick={() => setOpen(true)}
          >
            Get Started
          </button>
        </div>
        <div>
          <img className={style.img} src={img} alt="Powerful Android App Ads" />
        </div>
      </div>

      <ServicesPage />
      <IndustriesPage/>
      <Section2 />
      <Section3 />

   <ForthSection />
      <SixthSection />
      <EleventhSection />
      <Section5 />

      <ClientFormModal />
      <ProjectFormModal />

      <Footer />
    </div>
  );
}

export default AndroidAppAdvertising;
