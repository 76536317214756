import React, { useState } from "react";
import style from "./OnlineCourses.module.css";
import NavBar from "../../NavBar";
import img from "../../Image/Online Courses 1.png";
import Section2 from "./Section2/Section2";
import Section3 from "./Section3/Section3";
import SixthSection from "../../SixthSection/SixthSection";
import EleventhSection from "../../EleventhSection/EleventhSection";
import Footer from "../../Footer/Footer";

import Section4 from "./Section4/Section4";
import ForthSection from "../../ForthSection/ForthSection";

import Section5 from "./Section5/Section5";
import ClientFormModal from "../../ClientFormModal";
import ProjectFormModal from "../../ProjectFormModal";
import ServicesPage from "../../ServicesPage/ServicesPage";
import IndustriesPage from "../../IndustriesPage/IndustriesPage";
import { clientModaState } from "../../Recoil";
import { useRecoilState } from "recoil";
import Section from "./Section1/Section";
import { Helmet } from "react-helmet";

function OnlineCourses() {
  const [showPopup, setShowPopup] = useState(false);
  const [open, setOpen] = useRecoilState(clientModaState);

  //popup
  const handleGetStartedClick = () => {
    setShowPopup(true);
  };
  return (
    <div className={style.main}>
      <Helmet>
        <title>Online Course Mastery Development Services</title>
        <meta charSet="utf-8" />
        <meta
          name="Online Course Mastery Development Services"
          content="Expert WordPress development for engaging online courses. Elevate your educational platform effectively."
        />
        <link rel="canonical" href="https://www.easyryt.com/Online-Courses" />
      </Helmet>
      <NavBar />
      <div className={style.container}>
        <div>
          <h1>WordPress Online Courses Development Services</h1>
          <h6>
            Unleash Your Digital Capability with Our WordPress Online Website
            Creation Solutions.
          </h6>
          <p>
            Welcome to EasyRyt’s specialized WordPress Online Website Creation
            solutions, where ingenuity, design superiority, and technical
            expertise come together to form compelling online encounters. With
            WordPress as our artistic platform, we fashion websites that not
            only embody your brand but also enhance your virtual existence with
            effortless functionality and exquisite visuals.
          </p>
          <button
            type="submit"
            className={style.submitBtn}
            onClick={() => setOpen(true)}
          >
            Get Started
          </button>
        </div>
        <div className={style.img_box}>
          <img
            className={style.img}
            src={img}
            alt="WordPress Online Courses Development Services"
          />
        </div>
      </div>

      <ServicesPage />
      <IndustriesPage/>
      <Section2 />
      <Section3 />

   <ForthSection />
      <SixthSection />
      <EleventhSection />
      <Section5 />

      <ClientFormModal />
      <ProjectFormModal />

      <Footer />
    </div>
  );
}

export default OnlineCourses;
