import React from "react";
import "./App.css";
import NavBar from "./Components/NavBar";
import FormSection from "./Components/FormSection";
import WelcomePage from "./Components/Welcome";
import ForthSection from "./Components/ForthSection/ForthSection";
import SixthSection from "./Components/SixthSection/SixthSection";
import SeventhSection from "./Components/SeventhSection/SeventhSection";
import EightSection from "./Components/EightSection/EightSection";
import NinthSection from "./Components/NinthSection/NinthSection";
import TenthSection from "./Components/TenthSection/TenthSection";
import EleventhSection from "./Components/EleventhSection/EleventhSection";
import TwelvethSection from "./Components/TwelvethSection/TwelvethSection";
import ThirteenthSection from "./Components/ThirteenthSection/ThirteenthSection";
import ClientsAccordion from "./Components/ClientsAccordion/ClientsAccordion";
import Footer from "./Components/Footer/Footer";
import ServicesPage from "./Components/ServicesPage/ServicesPage";
import IndustriesPage from "./Components/IndustriesPage/IndustriesPage";

function App() {
  return (
    <div className="App">
      <NavBar />
      <WelcomePage />
      <FormSection />
      <ForthSection />
      <SixthSection />
      <SeventhSection />
      <EightSection />
      <NinthSection />
      <TenthSection />
      <ServicesPage />
      <IndustriesPage />
      <EleventhSection />
      <TwelvethSection />
      <ClientsAccordion />
      <Footer />
    </div>
  );
}

export default App;
