import React, { useState } from "react";
import style from "./BackendDevelopment.module.css";
import NavBar from "../../NavBar";
import img from "../../Image/Backend Development 1.png";
import Section2 from "./Section2/Section2";
import Section3 from "./Section3/Section3";
import SixthSection from "../../SixthSection/SixthSection";
import EleventhSection from "../../EleventhSection/EleventhSection";
import Footer from "../../Footer/Footer";

import Section4 from "./Section4/Section4";
import ForthSection from "../../ForthSection/ForthSection";

import Section5 from "./Section5/Section5";
import ClientFormModal from "../../ClientFormModal";
import ProjectFormModal from "../../ProjectFormModal";
import ServicesPage from "../../ServicesPage/ServicesPage";
import IndustriesPage from "../../IndustriesPage/IndustriesPage";
import { clientModaState } from "../../Recoil";
import { useRecoilState } from "recoil";
import Section from "./Section1/Section";
import { Helmet } from "react-helmet";

function BackendDevelopment() {
  const [showPopup, setShowPopup] = useState(false);
  const [open, setOpen] = useRecoilState(clientModaState);

  //popup
  const handleGetStartedClick = () => {
    setShowPopup(true);
  };
  return (
    <div className={style.main}>
      <Helmet>
        <title>Robust Back-End Solutions</title>
        <meta charSet="utf-8" />
        <meta
          name="Robust Back-End Solutions"
          content="Powering your web presence with secure and scalable back-end development. Elevate performance effortlessly."
        />
        <link rel="canonical" href="https://www.easyryt.com/Backend-Development" />
      </Helmet>
      <NavBar />
      <div className={style.container}>
        <div>
          <h1>Back-End Web Development Services</h1>
          <h6>Crafting Revolutionary Mobile Experiences</h6>
          <p>
            Welcome to our Back-End Web Development Services, where creativity
            merges with functionality to design sturdy and dynamic digital
            experiences. Serving as the backbone of your digital presence, the
            back end plays a vital role in guaranteeing the seamless, secure,
            and efficient operation of your website or application. With an
            in-depth understanding of back end development, we stand as your
            allies in creating influential solutions that propel your online
            endeavours forward.
          </p>
          <button
            type="submit"
            className={style.submitBtn}
            onClick={() => setOpen(true)}
          >
            Get Started
          </button>
        </div>
        <div className={style.img_box}>
          <img
            className={style.img}
            src={img}
            alt="Back-End Web Development Services"
          />
        </div>
      </div>

      <ServicesPage />
      <IndustriesPage/>
      <Section2 />
      <Section3 />

   <ForthSection />
      <SixthSection />
      <EleventhSection />
      <Section5 />

      <ClientFormModal />
      <ProjectFormModal />

      <Footer />
    </div>
  );
}

export default BackendDevelopment;
