import React, { useState } from "react";
import style from "./ThirdSection.module.css";
import { Link } from "react-router-dom";

const services = [
  { name: "App Development", path: "/App-Development", logo: "mob-logo.png", description: "App development is the process..." },
  { name: "Website Development", path: "/Website-Development", logo: "PWA.png", description: "Your online presence is critical..." },
  { name: "Digital Marketing", path: "/Digital-Marketing", logo: "erp.png", description: "Stay ahead of the competition..." },
  { name: "Cloud Solutions", path: "/Cloud-Solutions", logo: "graphic-design-.png", description: "Efficiently manage your data..." },
  { name: "WordPress Development", path: "/Word-Press-Development", logo: "digitalmarketing.png", description: "As a leading WordPress..." },
  { name: "UI/UX Design", path: "/UIUX-Design", logo: "Pm.png", description: "User experience is at the heart..." },
];

function ThirdSection() {
  const [hoveredOption, setHoveredOption] = useState(null);

  return (
    <div className={style.main}>
      <h1>Our Services</h1>
      <div className={style.navBar}>
        <ul className={style.optionList}>
          {services.map((service, index) => (
            <li
              key={index}
              className={hoveredOption === index ? style.active : ""}
              onMouseOver={() => setHoveredOption(index)}
              onMouseOut={() => setHoveredOption(null)}
            >
              {service.name}
              {hoveredOption === index && (
                <div className={style[`optionContent${index}`]}>
                  <img className={style.logo} src={require(`../Image/${service.logo}`)} alt="img" />
                  <h4>{service.name}</h4>
                  <p>{service.description}</p>
                  <Link to={service.path}>
                    <h6>Read More ᐳ</h6>
                  </Link>
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default ThirdSection;
