import React, { useState } from "react";
import style from "./WebsiteTrafficAdvertising.module.css";
import NavBar from "../../NavBar";
import img from "../../Image/Website Traffic Advertising 1.png";
import Section2 from "./Section2/Section2";
import Section3 from "./Section3/Section3";
import SixthSection from "../../SixthSection/SixthSection";
import EleventhSection from "../../EleventhSection/EleventhSection";
import Footer from "../../Footer/Footer";

import Section4 from "./Section4/Section4";
import ForthSection from "../../ForthSection/ForthSection";

import Section5 from "./Section5/Section5";
import ClientFormModal from "../../ClientFormModal";
import ProjectFormModal from "../../ProjectFormModal";
import ServicesPage from "../../ServicesPage/ServicesPage";
import IndustriesPage from "../../IndustriesPage/IndustriesPage";
import { clientModaState } from "../../Recoil";
import { useRecoilState } from "recoil";
import Section from "./Section1/Section";
import { Helmet } from "react-helmet";

function WebsiteTrafficAdvertising() {
  const [showPopup, setShowPopup] = useState(false);
  const [open, setOpen] = useRecoilState(clientModaState);

  //popup
  const handleGetStartedClick = () => {
    setShowPopup(true);
  };
  return (
    <div className={style.main}>
      <Helmet>
        <title>Advertising for Website Visitors</title>
        <meta charSet="utf-8" />
        <meta
          name="Advertising for Website Visitors"
          content="Turn visitors into customers with strategic advertising. Elevate your conversion rates effectively."
        />
        <link
          rel="canonical"
          href="https://www.easyryt.com/Website-Traffic-Advertising"
        />
      </Helmet>

      <NavBar />
      <div className={style.container}>
        <div>
          <h1>Advertising for Website Visitors</h1>
          <h6>Generate Traffic, Increase Interaction, Attain Expansion</h6>
          <p>
            Welcome to EasyRyt’s services for advertising website visitors,
            where we revolutionize your online presence from stagnant to
            dynamic, generating a constant flow of individuals to your webpage
            and unlocking a realm full of possibilities. In the thriving realm
            of the digital world, where visibility plays a crucial role,
            advertising for website visitors stands as the guiding light that
            directs potential clients to your virtual doorstep. Through our
            comprehensive strategies, we amplify the prominence of your brand,
            spark involvement, and pave the way for sustainable progress.
          </p>
          <button
            type="submit"
            className={style.submitBtn}
            onClick={() => setOpen(true)}
          >
            Get Started
          </button>
        </div>
        <div>
          <img
            className={style.img}
            src={img}
            alt="Advertising for Website Visitors"
          />
        </div>
      </div>

      <ServicesPage />
      <IndustriesPage/>
      <Section2 />
      <Section3 />

   <ForthSection />
      <SixthSection />
      <EleventhSection />
      <Section5 />

      <ClientFormModal />
      <ProjectFormModal />

      <Footer />
    </div>
  );
}

export default WebsiteTrafficAdvertising;
