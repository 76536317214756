import { atom } from "recoil";

// Manage the state of Project Modal (open/close)
export const projectModalState = atom({
  key: "projectModalState",
  default: false, // Initially closed
});

// Manage the state of Client Modal (open/close)
export const clientModaState = atom({
  key: "clientModaState",
  default: false, // Initially closed
});

// Store client data to pre-fill in the Project Form
export const clientDataState = atom({
  key: "clientDataState",
  default: {
    name: "",
    email: "",
    phone: "",
    requestServices: "",
  },
});
