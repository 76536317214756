import React, { useState } from "react";
import style from "./FashionLifestyleWebsite.module.css";
import NavBar from "../../NavBar";
import img from "../../Image/Facebook & Instagram Advertising 2.png";
import Section2 from "./Section2/Section2";
import Section3 from "./Section3/Section3";
import SixthSection from "../../SixthSection/SixthSection";
import EleventhSection from "../../EleventhSection/EleventhSection";
import Footer from "../../Footer/Footer";

import Section4 from "./Section4/Section4";
import ForthSection from "../../ForthSection/ForthSection";

import Section5 from "./Section5/Section5";
import ClientFormModal from "../../ClientFormModal";
import ProjectFormModal from "../../ProjectFormModal";
import ServicesPage from "../../ServicesPage/ServicesPage";
import IndustriesPage from "../../IndustriesPage/IndustriesPage";
import { clientModaState } from "../../Recoil";
import { useRecoilState } from "recoil";
import Section from "./Section1/Section";
import { Helmet } from "react-helmet";

function FashionLifestyleWebsite() {
  const [showPopup, setShowPopup] = useState(false);
  const [open, setOpen] = useRecoilState(clientModaState);

  //popup
  const handleGetStartedClick = () => {
    setShowPopup(true);
  };
  return (
    <div className={style.main}>
      <Helmet>
        <title>Fashion Website Development</title>
        <meta charSet="utf-8" />
        <meta
          name="Fashion Website Development"
          content="Expert WordPress development for stylish fashion websites. Elevate your brand's sartorial presence."
        />
        <link
          rel="canonical"
          href="https://www.easyryt.com/Fashion-Lifestyle-Website"
        />
      </Helmet>

      <NavBar />
      <div className={style.container}>
        <div>
          <h1>WordPress Fashion Website Development Services</h1>
          <h6>
            Elevate Your Brand with Professional Fashion Lifestyle Website
            Development Services
          </h6>
          <p>
            Welcome to EasyRyt’s specialized Fashion Lifestyle Website
            Development services, where creativity, aesthetic excellence, and
            technological expertise come together to create captivating online
            platforms that embody the essence of elegance and lifestyle. With a
            profound understanding of the fashion industry and web development
            proficiency, we design websites that not only display current trends
            but also inspire and captivate fashion enthusiasts.
          </p>
          <button
            type="submit"
            className={style.submitBtn}
            onClick={() => setOpen(true)}
          >
            Get Started
          </button>
        </div>
        <div className={style.img_box}>
          <img
            className={style.img}
            src={img}
            alt="WordPress Fashion Website Development Services"
          />
        </div>
      </div>

      <ServicesPage />
      <IndustriesPage/>
      <Section2 />
      <Section3 />

   <ForthSection />
      <SixthSection />
      <EleventhSection />
      <Section5 />

      <ClientFormModal />
      <ProjectFormModal />

      <Footer />
    </div>
  );
}

export default FashionLifestyleWebsite;
