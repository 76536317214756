import React, { useState } from "react";
import style from "./WebApplicationDevelopment.module.css";
import NavBar from "../../NavBar";
import img from "../../Image/Web Application Development, 1.png";
import Section2 from "./Section2/Section2";
import Section3 from "./Section3/Section3";
import SixthSection from "../../SixthSection/SixthSection";
import EleventhSection from "../../EleventhSection/EleventhSection";
import Footer from "../../Footer/Footer";

import Section4 from "./Section4/Section4";
import ForthSection from "../../ForthSection/ForthSection";

import Section5 from "./Section5/Section5";
import ClientFormModal from "../../ClientFormModal";
import ProjectFormModal from "../../ProjectFormModal";
import ServicesPage from "../../ServicesPage/ServicesPage";
import IndustriesPage from "../../IndustriesPage/IndustriesPage";
import { clientModaState } from "../../Recoil";
import { useRecoilState } from "recoil";
import Section from "./Section1/Section";
import { Helmet } from "react-helmet";

function WebApplicationDevelopment() {
  const [showPopup, setShowPopup] = useState(false);
  const [open, setOpen] = useRecoilState(clientModaState);

  //popup
  const handleGetStartedClick = () => {
    setShowPopup(true);
  };
  return (
    <div className={style.main}>
      <Helmet>
        <title>Web App Development Services</title>
        <meta charSet="utf-8" />
        <meta
          name="Web App Development Services"
          content="Crafting tailored web apps for optimal performance. Elevate your digital solutions with expert development services."
        />
        <link
          rel="canonical"
          href="https://www.easyryt.com/Web-Application-Development"
        />
      </Helmet>

      <NavBar />
      <div className={style.container}>
        <div>
          <h1>Web Application Development Services</h1>
          <h6>Discover Our Extensive Array of AWS Solutions</h6>
          <p>
            Welcome to EasyRyt’s dedicated page for specialized services in
            developing web applications, where creativity combines with advanced
            web solutions. Are you prepared to convert your concepts into
            interactive web applications? Search no more. Our skilled team of
            web developers is present to design extraordinary web applications
            that enhance your online presence.
          </p>
          <button
            type="submit"
            className={style.submitBtn}
            onClick={() => setOpen(true)}
          >
            Get Started
          </button>
        </div>
        <div className={style.img_box}>
          <img
            className={style.img}
            src={img}
            alt="Web App Development Services"
          />
        </div>
      </div>

      <ServicesPage />
      <IndustriesPage/>
      <Section2 />
      <Section3 />

   <ForthSection />
      <SixthSection />
      <EleventhSection />
      <Section5 />

      <ClientFormModal />
      <ProjectFormModal />

      <Footer />
    </div>
  );
}

export default WebApplicationDevelopment;
