import React, { useState } from "react";
import style from "./DigitalMarketing.module.css";
import NavBar from "../../NavBar";
import img from "../../Image/digitalmarketing.png";
import Section1 from "./Section1/Section1";
import Section2 from "../FirstPage/Section2/Section2";
import Section3 from "../FirstPage/Section3/Section3";
import TenthSection from "../../TenthSection/TenthSection";
import SixthSection from "../../SixthSection/SixthSection";
import EleventhSection from "../../EleventhSection/EleventhSection";
import Footer from "../../Footer/Footer";
import { useRecoilState } from "recoil";
import { clientModaState } from "../../Recoil";
import { Helmet } from "react-helmet";
import ServicesPage from "../../ServicesPage/ServicesPage";
import IndustriesPage from "../../IndustriesPage/IndustriesPage";

function DigitalMarketing() {
  const [showPopup, setShowPopup] = useState(false);
  const [open, setOpen] = useRecoilState(clientModaState);

  const handleGetStartedClick = () => {
    setShowPopup(true);
  };
  return (
    <div className={style.main}>
      <Helmet>
        <title>Digital Marketing Solutions - EasyRyt</title>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Boost your online presence with EasyRyt's digital marketing solutions. From SEO to social media, we drive results to help grow your business."
        />
        <link
          rel="canonical"
          href="https://www.easyryt.com/Digital-Marketing"
        />
      </Helmet>
      <NavBar />
      <div className={style.container}>
        <div>
          <h1>Digital MarketingServices</h1>
          <p>
            Boost your online presence with our comprehensive Digital Marketing
            Services. We offer expert strateg DigitalMarketing
          </p>
          <button
            type="submit"
            className={style.submitBtn}
            onClick={() => setOpen(true)}
          >
            Get Started
          </button>
        </div>
        <div>
          <img
            className={style.img}
            src={img}
            alt="Digital MarketingServices"
          />
        </div>
      </div>

      <ServicesPage />
      <IndustriesPage />
      <Section2 />
      <Section3 />
      <TenthSection />
      <SixthSection />
      <EleventhSection />
      <Footer />
    </div>
  );
}

export default DigitalMarketing;
