import React, { useState, useEffect } from "react";
import style from "./NinthSection.module.css";
import BusinessIcon from "@mui/icons-material/Business";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AccessibilityNewIcon from "@mui/icons-material/AccessibilityNew";
import InsightsIcon from "@mui/icons-material/Insights";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import StarIcon from "@mui/icons-material/Star";

const cardData = [
  {
    icon: <BusinessIcon className={style.icon} />,
    title: "Enhanced Customer Engagement",
    description:
      "Mobile apps offer direct communication channels, enabling businesses to interact with customers, provide personalized experiences, and gather valuable feedback.",
  },
  {
    icon: <VisibilityIcon className={style.icon} />,
    title: "Increased Brand Visibility",
    description:
      "Mobile apps, once downloaded, stay on users' devices, reminding them of your brand and increasing brand recall.",
  },
  {
    icon: <AccessibilityNewIcon className={style.icon} />,
    title: "Improved Accessibility",
    description:
      "Mobile apps allow users to access your products or services anytime, anywhere, leading to a more convenient and seamless user experience.",
  },
  {
    icon: <InsightsIcon className={style.icon} />,
    title: "Higher Efficiency",
    description:
      "Apps can streamline processes, automate tasks, and optimize workflows, resulting in increased operational efficiency for businesses.",
  },
  {
    icon: <MonetizationOnIcon className={style.icon} />,
    title: "New Revenue Streams",
    description:
      "Mobile apps can open up new opportunities for monetization, such as in-app purchases, subscriptions, or advertising, expanding revenue potential.",
  },
  {
    icon: <StarIcon className={style.icon} />,
    title: "Competitive Advantage",
    description:
      "In today's mobile-centric world, having a well-designed and functional app can set your business apart from competitors and attract tech-savvy users.",
  },
];

function NinthSection() {
  return (
    <div className={style.main}>
      <h1 className={style.title}>Benefits of Mobile App Development</h1>
      <p className={style.description}>
        Here are some of the most significant advantages of mobile app growth
        for businesses.
      </p>
      <div className={style.innerbox}>
        {cardData.map((data, index) => (
          <div className={style.card} key={index}>
            <div className={style.iconContainer}>{data.icon}</div>
            <h6 className={style.cardTitle}>{data.title}</h6>
            <p className={style.cardDescription}>{data.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default NinthSection;
