import React, { useState } from "react";
import style from "./PerformanceOptimization.module.css";
import NavBar from "../../NavBar";
import img from "../../Image/Performance Optimization, 1.png";
import Section2 from "./Section2/Section2";
import Section3 from "./Section3/Section3";
import SixthSection from "../../SixthSection/SixthSection";
import EleventhSection from "../../EleventhSection/EleventhSection";
import Footer from "../../Footer/Footer";

import Section4 from "./Section4/Section4";
import ForthSection from "../../ForthSection/ForthSection";

import Section5 from "./Section5/Section5";
import ClientFormModal from "../../ClientFormModal";
import ProjectFormModal from "../../ProjectFormModal";
import ServicesPage from "../../ServicesPage/ServicesPage";
import IndustriesPage from "../../IndustriesPage/IndustriesPage";
import { clientModaState } from "../../Recoil";
import { useRecoilState } from "recoil";
import Section from "./Section1/Section";
import { Helmet } from "react-helmet";

function PerformanceOptimization() {
  const [showPopup, setShowPopup] = useState(false);
  const [open, setOpen] = useRecoilState(clientModaState);

  //popup
  const handleGetStartedClick = () => {
    setShowPopup(true);
  };
  return (
    <div className={style.main}>
      <Helmet>
        <title>Performance Optimization Services</title>
        <meta charSet="utf-8" />
        <meta
          name="Performance Optimization Services"
          content="Boost efficiency and speed with expert optimization services. Elevate your digital solutions seamlessly."
        />
        <link
          rel="canonical"
          href="https://www.easyryt.com/Performance-Optimization"
        />
      </Helmet>

      <NavBar />
      <div className={style.container}>
        <div>
          <h1>Performance Optimization Services</h1>
          <h6>Discover Our Extensive Array of AWS Solutions</h6>
          <p>
            Welcome to EasyRyt’s specialized page for Expert Performance
            Enhancement Services, where creativity intersects with peak
            performance solutions. Are you prepared to unlock the genuine
            capacity of your digital platforms? Explore no further. Our skilled
            group of specialists in performance optimization is here to enhance
            your online existence by optimizing speed, effectiveness, and user
            delight.
          </p>
          <button
            type="submit"
            className={style.submitBtn}
            onClick={() => setOpen(true)}
          >
            Get Started
          </button>
        </div>
        <div className={style.img_box}>
          <img
            className={style.img}
            src={img}
            alt="Performance Optimization Services"
          />
        </div>
      </div>

      <ServicesPage />
      <IndustriesPage/>
      <Section2 />
      <Section3 />

   <ForthSection />
      <SixthSection />
      <EleventhSection />
      <Section5 />

      <ClientFormModal />
      <ProjectFormModal />

      <Footer />
    </div>
  );
}

export default PerformanceOptimization;
