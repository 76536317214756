import React, { useState } from "react";
import style from "./BusinessWebsite.module.css";
import NavBar from "../../NavBar";
import img from "../../Image/App UI.UX Design Services 1.png";
import Section2 from "./Section2/Section2";
import Section3 from "./Section3/Section3";
import SixthSection from "../../SixthSection/SixthSection";
import EleventhSection from "../../EleventhSection/EleventhSection";
import Footer from "../../Footer/Footer";

import Section4 from "./Section4/Section4";
import ForthSection from "../../ForthSection/ForthSection";

import Section5 from "./Section5/Section5";
import ClientFormModal from "../../ClientFormModal";
import ProjectFormModal from "../../ProjectFormModal";
import ServicesPage from "../../ServicesPage/ServicesPage";
import IndustriesPage from "../../IndustriesPage/IndustriesPage";
import { clientModaState } from "../../Recoil";
import { useRecoilState } from "recoil";
import Section from "./Section1/Section";
import { Helmet } from "react-helmet";

function BusinessWebsite() {
  const [showPopup, setShowPopup] = useState(false);
  const [open, setOpen] = useRecoilState(clientModaState);

  //popup
  const handleGetStartedClick = () => {
    setShowPopup(true);
  };
  return (
    <div className={style.main}>
      <Helmet>
        <title>WordPress Business Development Services</title>
        <meta charSet="utf-8" />
        <meta
          name="WordPress Business Development Services"
          content="Empower your brand with expert WordPress business development services. Elevate online success."
        />
        <link
          rel="canonical"
          href="https://www.easyryt.com/Business-Website"
        />
      </Helmet>

      <NavBar />
      <div className={style.container}>
        <div>
          <h1>WordPress Business Development Services</h1>
          <h6>Revamp Your Enterprise with Our Extensive WordPress Corporate</h6>
          <p>
            Welcome to the domain of our WordPress Corporate Website Creation,
            where creativity, expertise in design, and technical proficiency
            unite to propel your enterprise towards online recognition. Equipped
            with WordPress as our primary instrument and your ambitions as our
            compass, we fabricate digital platforms that go beyond mere
            functionality - they become driving forces for expansion.
          </p>
          <button
            type="submit"
            className={style.submitBtn}
            onClick={() => setOpen(true)}
          >
            Get Started
          </button>
        </div>
        <div className={style.img_box}>
          <img
            className={style.img}
            src={img}
            alt="WordPress Business Development Services"
          />
        </div>
      </div>

      <ServicesPage />
      <IndustriesPage/>
      <Section2 />
      <Section3 />

   <ForthSection />
      <SixthSection />
      <EleventhSection />
      <Section5 />

      <ClientFormModal />
      <ProjectFormModal />

      <Footer />
    </div>
  );
}

export default BusinessWebsite;
