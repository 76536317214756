import React from "react";
import { Container, Grid, Paper, Typography } from "@mui/material";
import { Healthcare, AccountBalance, Shop, LocalShipping, Group, FlightTakeoff, Restaurant, Home, School, Work, VideogameAsset, Public, LocalFarm, Theaters } from "@mui/icons-material"; // Material-UI icons for each industry
import styles from "./IndustriesPage.module.css";
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import AgricultureIcon from '@mui/icons-material/Agriculture';

// Industry Data with Icons
const industries = [
  {
    title: "Healthcare",
    icon: <HealthAndSafetyIcon fontSize="large" />,
  },
  {
    title: "Finance",
    icon: <AccountBalance fontSize="large" />,
  },
  {
    title: "E-Commerce",
    icon: <Shop fontSize="large" />,
  },
  {
    title: "Logistics",
    icon: <LocalShipping fontSize="large" />,
  },
  {
    title: "Social Networking",
    icon: <Group fontSize="large" />,
  },
  {
    title: "Travel",
    icon: <FlightTakeoff fontSize="large" />,
  },
  {
    title: "Restaurants",
    icon: <Restaurant fontSize="large" />,
  },
  {
    title: "Real Estate",
    icon: <Home fontSize="large" />,
  },
  {
    title: "Education",
    icon: <School fontSize="large" />,
  },
  {
    title: "On Demand",
    icon: <Work fontSize="large" />,
  },
  {
    title: "Games",
    icon: <VideogameAsset fontSize="large" />,
  },
  {
    title: "Aviation",
    icon: <FlightTakeoff fontSize="large" />,
  },
  {
    title: "Government",
    icon: <Public fontSize="large" />,
  },
  {
    title: "Agriculture",
    icon: <AgricultureIcon fontSize="large" />,
  },
  {
    title: "Entertainment",
    icon: <Theaters fontSize="large" />,
  },
];

const IndustriesPage = () => {
  return (
    <div className={styles.pageContainer}>
      <Container>
        <h1>
          Industries We Cater To
        </h1>
        <Typography variant="h5" align="center" color="gray" paragraph>
          As a leading mobile app development company with 600+ engineers, we offer digital solutions that drive your business growth.
        </Typography>
        <br/>
        <Grid container spacing={4}>
          {industries.map((industry, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Paper className={styles.industryCard} elevation={5}>
                <div className={styles.iconWrapper}>
                  {industry.icon}
                </div>
                <Typography variant="h6" className={styles.industryTitle}>
                  {industry.title}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
};

export default IndustriesPage;
