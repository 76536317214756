import React, { useState } from "react";
import style from "./PortfolioWebsites.module.css";
import NavBar from "../../NavBar";
import img from "../../Image/Portfolio Websites 1.png";
import Section2 from "./Section2/Section2";
import Section3 from "./Section3/Section3";
import SixthSection from "../../SixthSection/SixthSection";
import EleventhSection from "../../EleventhSection/EleventhSection";
import Footer from "../../Footer/Footer";

import Section4 from "./Section4/Section4";
import ForthSection from "../../ForthSection/ForthSection";

import Section5 from "./Section5/Section5";
import ClientFormModal from "../../ClientFormModal";
import ProjectFormModal from "../../ProjectFormModal";
import ServicesPage from "../../ServicesPage/ServicesPage";
import IndustriesPage from "../../IndustriesPage/IndustriesPage";
import { clientModaState } from "../../Recoil";
import { useRecoilState } from "recoil";
import Section from "./Section1/Section";
import { Helmet } from "react-helmet";

function PortfolioWebsites() {
  const [showPopup, setShowPopup] = useState(false);
  const [open, setOpen] = useRecoilState(clientModaState);

  //popup
  const handleGetStartedClick = () => {
    setShowPopup(true);
  };
  return (
    <div className={style.main}>
      <Helmet>
        <title>Portfolio Website Development - EasyRyt</title>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Showcase your work with stunning portfolio websites. EasyRyt specializes in creating personalized and professional portfolios to elevate your online presence."
        />
        <link
          rel="canonical"
          href="https://www.easyryt.com/Portfolio-Websites"
        />
      </Helmet>
      <NavBar />
      <div className={style.container}>
        <div>
          <h1>WordPress Portfolio Website Development Services</h1>
          <h6>
            Display Your Skills with Professional WordPress Portfolio Website
            Development Services
          </h6>
          <p>
            Welcome to EasyRyt’s specialized services for developing expert
            WordPress portfolio websites. We bring together innovation, finesse
            in design, and technical proficiency to create captivating online
            portfolios that highlight your distinctive abilities. With our
            extensive knowledge of WordPress and its capabilities, we design
            websites that not only showcase your work but also make a lasting
            impact on your visitors.
          </p>
          <button
            type="submit"
            className={style.submitBtn}
            onClick={() => setOpen(true)}
          >
            Get Started
          </button>
        </div>
        <div className={style.img_box}>
          <img
            className={style.img}
            src={img}
            alt="WordPress Portfolio Website Development Services"
          />
        </div>
      </div>

      <ServicesPage />
      <IndustriesPage/>
      <Section2 />
      <Section3 />

   <ForthSection />
      <SixthSection />
      <EleventhSection />
      <Section5 />

      <ClientFormModal />
      <ProjectFormModal />

      <Footer />
    </div>
  );
}

export default PortfolioWebsites;
