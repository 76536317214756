import React, { useState, useEffect } from 'react';
import style from './EightSection.module.css';

const EightSection= () => {
  const industries = [
    'Usability First',
    'Push Notification',
    'FeedBack System',
    'Social Integration',
    'Customization',
    'Augmented Reality',
    'Google Indoor-Maps',
    'Payment Gateway Integration',
    'Advance Analytics',
    'One-Click Contacting',
    'QR/Barcode Scanner Integration',
    'Security',
  ];

  const [currentIndustryIndex, setCurrentIndustryIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndustryIndex(getRandomIndex(industries.length));
    }, 2000); // 2-second delay

    return () => clearInterval(interval);
  }, []);

  const getRandomIndex = (length) => {
    return Math.floor(Math.random() * length);
  };

  return (
    <div className={style.main}>
            <h1>Features Of Front End Development</h1>
      <p className={style.para}>
        The Following Are Essential Mobile App Development Featurees To Considen
        When Developing a Successful Application For Your Company
      </p>
      <div className={style.innerbox}>
        {industries.map((industry, index) => (
          <p key={index} className={index === currentIndustryIndex ? style.active : style.notActive}>
            {industry}
          </p>
        ))}
      </div>
    </div>
  );
};

export default EightSection;



