import React, { useState } from "react";
import style from "./Azure.module.css";
import NavBar from "../../NavBar";
import img from "../../Image/Azure 1.png";
import Section2 from "./Section2/Section2";
import Section3 from "./Section3/Section3";
import SixthSection from "../../SixthSection/SixthSection";
import EleventhSection from "../../EleventhSection/EleventhSection";
import Footer from "../../Footer/Footer";

import Section4 from "./Section4/Section4";
import ForthSection from "../../ForthSection/ForthSection";

import Section5 from "./Section5/Section5";
import ClientFormModal from "../../ClientFormModal";
import ProjectFormModal from "../../ProjectFormModal";
import ServicesPage from "../../ServicesPage/ServicesPage";
import IndustriesPage from "../../IndustriesPage/IndustriesPage";
import { clientModaState } from "../../Recoil";
import { useRecoilState } from "recoil";
import Section from "./Section1/Section";
import { Helmet } from "react-helmet";

function Azure() {
  const [showPopup, setShowPopup] = useState(false);
  const [open, setOpen] = useRecoilState(clientModaState);

  //popup
  const handleGetStartedClick = () => {
    setShowPopup(true);
  };
  return (
    <div className={style.main}>
      <Helmet>
        <title>Azure Services</title>
        <meta charSet="utf-8" />
        <meta
          name="Azure Services"
          content="Leverage our expert Azure services for seamless cloud solutions. Elevate your digital infrastructure effortlessly."
        />
        <link rel="canonical" href="https://www.easyryt.com/Azure" />
      </Helmet>

      <NavBar />
      <div className={style.container}>
        <div>
          <h1>Azure Services</h1>
          <h6>
            Unleash the Potential of Your Business with State-of-the-Art Azure
            Solutions
          </h6>
          <p>
            Welcome to EasyRyt’s all-inclusive Azure Solutions page, where you
            embark on your path to fully leverage the power of cloud technology.
            Are you prepared to propel your enterprise into the forefront of the
            future, utilizing the unmatched capabilities of Microsoft Azure?
            Search no more. Our array of Azure solutions is carefully crafted to
            satisfy the needs of businesses in search of creativity,
            productivity, and expansion in the digital realm.
          </p>
          <button
            type="submit"
            className={style.submitBtn}
            onClick={() => setOpen(true)}
          >
            Get Started
          </button>
        </div>
        <div className={style.img_box}>
          <img className={style.img} src={img} alt="Azure Services" />
        </div>
      </div>

      <ServicesPage />
      <IndustriesPage/>
      <Section2 />
      <Section3 />

   <ForthSection />
      <SixthSection />
      <EleventhSection />
      <Section5 />

      <ClientFormModal />
      <ProjectFormModal />

      <Footer />
    </div>
  );
}

export default Azure;
