import React from "react";
import style from "./Section4.module.css";
import { Link } from "react-router-dom";

function Section4() {
  return (
    <div className={style.main}>
      <p>
        Unleash Your Website's Potential with EasyRyt SEO Services. Do not
        permit your website to become lost in the colossal online realm. With
        our comprehensive SEO services, you can accomplish higher search engine
        rankings, amplified natural traffic, and a more robust online presence.
      </p>
      <h2>Get in touch with us today</h2>
      <p>
        Let’s discuss discuss how we can enhance your brand with a Native mobile
        app that sets you apart in the digital landscape!
      </p>
      <button
        onClick={() => (window.location.href = "/Contact-Us")}
        className={style.btn}
      >
        Start Conversation
      </button>
    </div>
  );
}

export default Section4;
