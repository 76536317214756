import React, { useState } from "react";
import style from "./MobileBackendDevelopment.module.css";
import NavBar from "../../NavBar";
import img from "../../Image/Mobile Backend Development, 1.png";
import Section2 from "./Section2/Section2";
import Section3 from "./Section3/Section3";
import SixthSection from "../../SixthSection/SixthSection";
import EleventhSection from "../../EleventhSection/EleventhSection";
import Footer from "../../Footer/Footer";

import Section4 from "./Section4/Section4";
import ForthSection from "../../ForthSection/ForthSection";

import Section5 from "./Section5/Section5";
import ClientFormModal from "../../ClientFormModal";
import ProjectFormModal from "../../ProjectFormModal";
import ServicesPage from "../../ServicesPage/ServicesPage";
import IndustriesPage from "../../IndustriesPage/IndustriesPage";
import { clientModaState } from "../../Recoil";
import { useRecoilState } from "recoil";
import Section from "./Section1/Section";
import { Helmet } from "react-helmet";

function MobileBackendDevelopment() {
  const [showPopup, setShowPopup] = useState(false);
  const [open, setOpen] = useRecoilState(clientModaState);

  //popup
  const handleGetStartedClick = () => {
    setShowPopup(true);
  };
  return (
    <div className={style.main}>
      <Helmet>
        <title>Mobile Backend Development</title>
        <meta charSet="utf-8" />
        <meta
          name="Mobile Backend Development"
          content="Optimize mobile apps with our backend development services. Elevate performance seamlessly."
        />
        <link
          rel="canonical"
          href="https://www.easyryt.com/Mobile-Backend-Development"
        />
      </Helmet>

      <NavBar />
      <div className={style.container}>
        <div>
          <h1>Mobile Backend Development Services</h1>
          <h6>Discover Our Extensive Array of AWS Solutions</h6>
          <p>
            Welcome to EasyRyt’s dedicated page for specialized services in
            developing backends for mobile applications. Here, we bring together
            cutting-edge ideas with strong backend solutions. If you are looking
            to enhance the capabilities of your mobile app by implementing a
            robust backend infrastructure, you have come to the right place. Our
            team of knowledgeable backend developers is ready to craft flawless
            and high-performing backend systems that power the functionality of
            your mobile app.
          </p>
          <button
            type="submit"
            className={style.submitBtn}
            onClick={() => setOpen(true)}
          >
            Get Started
          </button>
        </div>
        <div className={style.img_box}>
          <img
            className={style.img}
            src={img}
            alt="Mobile Backend Development Services"
          />
        </div>
      </div>

      <ServicesPage />
      <IndustriesPage/>
      <Section2 />
      <Section3 />

   <ForthSection />
      <SixthSection />
      <EleventhSection />
      <Section5 />

      <ClientFormModal />
      <ProjectFormModal />

      <Footer />
    </div>
  );
}

export default MobileBackendDevelopment;
